import React, { useRef, useState } from 'react';
import './Thumb.scss';
import './MediaThumb.scss';
import { IThumb } from '../../model/cardModel';
import { copiedLinkState, creatorBrand, popup } from '../../redux/rootSlice';
import { useDispatch, useSelector } from 'react-redux';
import MuxPlayer, { MuxPlayerRefAttributes } from "@mux/mux-player-react";
import { companyType } from "../../model/user";
import  { addAdToCollection, popupMethod } from "../../redux/myCollection";

interface ThumbModel {
  avatar: IThumb['avatar'];
  userName: IThumb['userName'];
  playbackId: string;
  videoId: string;
  viewerId: string;
  videoTitle: string;
  isFavorite?: boolean;
  userId?: string;
  originalLink?: string;
  companyTypeP?: companyType;
  blurred?: boolean;
}

export default function ThumbAds({ avatar, userName, playbackId, videoId, viewerId, videoTitle, companyTypeP, isFavorite, userId, originalLink, blurred }: ThumbModel) {
  const [playClass, setPlayClass] = useState<string>('playBtn');
  const playerRef = useRef<MuxPlayerRefAttributes>(null);
  const [favorited, setFavorited] = useState<boolean | undefined>(isFavorite);
  const isFavoriteClass = favorited ? 'notesBtn-favorited' : 'notesBtn';
  const userIsGuest = useSelector((state: any) => state.logInState.guestStatus);

  const dispatch = useDispatch();

  const togglePlay = async () => {
    if (blurred) {
      return
    }
    if (playClass === 'playBtn pauseHover' || playClass === 'playBtn') {
      setPlayClass('pauseBtn');
      await playerRef.current?.play();
    } else {
      setPlayClass('playBtn pauseHover');
      playerRef.current?.pause();
    }
  };

  async function handleLinkCopied(): Promise<void> {
    if (blurred) {
      return
    }
    dispatch(copiedLinkState('copied-modal-open'));
    const urlToShare = `${window.location.origin}/ad/${videoId}`;
    await navigator.clipboard.writeText(urlToShare);

    setTimeout(() => {
      dispatch(copiedLinkState('copied-modal-close'));
    }, 5000);
  }

  async function handleFavorited(): Promise<void> {
    if (blurred) {
      return
    }
    if (userIsGuest) {
      dispatch(popup('open-signIn'));
      return;
    }
    dispatch(popupMethod('saveCollection'));
    dispatch(addAdToCollection({ id: videoId }));
  }

  return (
    <div className='thumbItemWrapper'>
      <div
        className='thumbItem'
        onClick={() => {
          dispatch(creatorBrand('creator'));
        }}
      >
        <div className='thumbImg'>
          <MuxPlayer
            ref={playerRef}
            className={blurred ? 'mux-player thumbitemBlurred' : 'mux-player'}
            streamType='on-demand'
            playbackId={playbackId}
            metadata={{
              video_id: videoId,
              video_title: videoTitle,
              viewer_user_id: viewerId
            }}
          />
        </div>
        <div className='thumbHover'>
          {/* TODO make function for copyBtn to show popup "Link copied" */}
          <button className='copyBtn' onClick={handleLinkCopied}></button>
          {/* TODO make function for notesBtn to show popup with login */}
          <button className={isFavoriteClass} onClick={handleFavorited}></button>
          {/* TODO make function for edit button */}
          {/* <button className='editBtn'></button> */}


        </div>
        <button className={playClass} onClick={() => togglePlay()}></button>
      </div>
    </div>
  );
}
