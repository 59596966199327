// import { DataRow } from '../model/dataRow';

const rowsArray: any[] = [
    {
        id: 1,
        name: 'User name #1',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 1
    },
    {
        id: 2,
        name: 'User name #2',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 2
    },
    {
        id: 3,
        name: 'User name #3',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 5
    },
    {
        id: 4,
        name: 'User name #4',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 10
    },
    {
        id: 5,
        name: 'User name #5',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 15
    },
    {
        id: 6,
        name: 'User name #6',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 20
    },
    {
        id: 7,
        name: 'User name #7',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 25
    },
    {
        id: 8,
        name: 'User name #8',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 30
    },
    {
        id: 9,
        name: 'User name #9',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 35
    },
    {
        id: 10,
        name: 'User name #10',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 40
    },
    {
        id: 11,
        name: 'User name #11',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 45
    },
    {
        id: 12,
        name: 'User name #12',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 50
    },
    {
        id: 13,
        name: 'User name #13',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 55
    },
    {
        id: 14,
        name: 'User name #14',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 60
    },
    {
        id: 15,
        name: 'User name #15',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 65
    },
    {
        id: 16,
        name: 'User name #16',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 70
    },
    {
        id: 17,
        name: 'User name #17',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 75
    },
    {
        id: 18,
        name: 'User name #18',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 80
    },
    {
        id: 19,
        name: 'User name #19',
        email: 'useremail@gmail.com',
        type: 'Text',
        videos: 85
    }
];

export default rowsArray;
