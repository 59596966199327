import React from "react";
import './ThumbWrapper.scss'
import './MediaThumbWrapper.scss'
import Thumb from "../Thumb/Thumb";
import { useSelector } from "react-redux";
import userProfileImg from "../../assets/images/user-avatar.png";
import { useGetAdsFromCollectionQuery } from "../../services/adinspiration-api";
import { useParams } from "react-router-dom";

const ThumbsAdsFromCollection = () => {
  const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
  const urlParam = useParams();

  const { data, error, isLoading } = useGetAdsFromCollectionQuery({
    collectionId:  urlParam?.id,
  }, {
    refetchOnMountOrArgChange: true,
  });

  if (error) {
    return <div>Something went wrong</div>
  }

  return (
    <div>
      {isLoading ? null :
        <div className="thumbWrapper">
          {data?.videos?.map((item) => {
            if (guestStatus) {
              return (
                <div key={item._id}>
                  {item.playbackIds.map((playbackId) => (
                    <Thumb
                      key={playbackId.id}
                      avatar={item.user.avatar || userProfileImg}
                      userName={item.user.firstName}
                      videoId={item._id}
                      playbackId={playbackId.id}
                      viewerId={'Guest'}
                      videoTitle={item.title}
                      userId={item.user._id}
                      originalLink={item.originalLink}
                      collectionId={urlParam?.id}
                      videoCollectionOwner={data?.user._id}
                      //isFavorite={item.isFavorite}
                    />
                  ))}
                </div>
              );
            } else if (!guestStatus) {
              return (
                <div key={item._id}>
                  {item.playbackIds.map((playbackId) => (
                    <Thumb
                      key={playbackId.id}
                      avatar={item.user.avatar || userProfileImg}
                      userName={item.user.firstName}
                      videoId={item._id}
                      playbackId={playbackId.id}
                      viewerId={item.user._id}
                      videoTitle={item.title}
                      originalLink={item.originalLink}
                      //isFavorite={item.isFavorite}
                      userId={item.user._id}
                      collectionId={urlParam?.id}
                      videoCollectionOwner={data?.user._id}
                    />
                  ))}
                </div>
              );
            }
            return false;
          })}
        </div>
      }
    </div>
  );
}

export default ThumbsAdsFromCollection;
