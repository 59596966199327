import React from "react";
import './SearchQuery.scss'
import UnloggedFooter from "../../components/Footer/UnlogFooter/UnlogFooter";
import SearchFilters from "../../components/SearchFilters/SearchFilters";
import { useSelector } from "react-redux";
import GetUnlimAccess from "../../components/Modals/SelectPlanModal/GetUnlimAccess";
import Footer from "../../components/Footer/Footer";
import ThumbAdsSearchWrapper from "../../components/ThumbWrapper/ThumbAdsSearchWrapper";



const SearchQuery = () => {
    const [numbersOfAds, setNumbersOfAds] = React.useState<number>(0);
    const [numberOfBlurredAds, setNumberOfBlurredAds] = React.useState<number>(0);
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const getUnlimAccessModal = useSelector((state: any) => state.selectPlanState.getUnlimAccessModal);
    const queryParams = new URLSearchParams(window.location.search);


    return (
      <>
          { getUnlimAccessModal && <GetUnlimAccess/> }
          <div className="searchPage">
              <div className="container">
                  <div className="search-page-wrapper">
                      <div className="searchTitle">
                          <div className="title">
                              <h2>
                                  {queryParams.get('search') ? queryParams.get('search') : "All Ads"}
                              </h2>
                          </div>
                          <div className="description">
                                <span>
                                {`${queryParams.get('search') ? numbersOfAds + numberOfBlurredAds : numbersOfAds} Facebook, Instagram, Tiktok Ads of Top Brands and Creators`}
                                </span>
                          </div>
                      </div>
                      <SearchFilters/>
                      <ThumbAdsSearchWrapper setNumberAds={setNumbersOfAds} setNumberOfBlurredAds={setNumberOfBlurredAds}/>
                  </div>
                  {guestStatus && <UnloggedFooter />}
              </div>
          </div>
          {!guestStatus && <Footer />}
      </>
    );
};

export default SearchQuery
