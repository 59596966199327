import React from 'react';
import Thumb from "../../Thumb/Thumb";
import userProfileImg from "../../../assets/images/user-avatar.png";
import './BlurredVideo.scss';

const BlurredVideos = () => {

  const renderBlurredThumbs = () => {
    return Array.from({ length: 12 }, (_, index) => (
      <Thumb key={`blurred-${index}`} blurred={true} avatar={userProfileImg} />
    ));
  };

  return (
    <>
        {renderBlurredThumbs()}
    </>
  );
};

export default BlurredVideos;
