import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './MigrationBrandPage.scss';
import closeBtn from '../../../../assets/images/closeBtn.svg';
import {
  useMigrateDataBrandsMutation,
} from "../../../../services/adinspiration-api";
import { submitPage } from "../../../../redux/submitWorkSlice";
import DefaultBtn from "../../../../components/shared/DefaultBtn";

const MigrationBrandPage = () => {
  const dispatch = useDispatch();
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [triggerUpload, { isLoading }] = useMigrateDataBrandsMutation();

  const resetForm = () => {
    setVideoFile(null);
  }

  const handleVideoFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Check if files are selected and not null
    if (e.target.files && e.target.files.length > 0) {
      setVideoFile(e.target.files[0]); // Safely update the state with the selected video file
    } else {
      setVideoFile(null); // Reset or handle the case when no file is selected
    }
  };


  useEffect(() => {
    dispatch(submitPage('open-submit-page'));
  }, [dispatch]);


  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // For example, dispatch an action to submit the form data
    try {
      if (videoFile) {
        await triggerUpload({
          xlsxFile: videoFile,
        }).unwrap();
        resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='submitWorkPage'>
      <form onSubmit={handleSubmit}>
        <div className='container'>
          <div className='submitPageWrapper'>
            <div className='closeBtn' onClick={() => {}}>
              <img src={closeBtn} alt='close-btn' />
            </div>
            <div className='submitTitle'>
              <h2>Add new ad creative, Brands</h2>
            </div>
            <div className="contentWrapper">

              {/* Video File Upload */}
              <div className="brandCategory">
                <div className="brand">
                  <div className="contentTitle">
                    <h5>XLSX File</h5>
                  </div>
                  <div className="itemWrapper">
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // This restricts the file input to accept only video files
                      onChange={handleVideoFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="buttonWrapper">
                <div className="adNew">
                  <DefaultBtn textBtn="Migrate from file" methodBtn="add-new-ad" disabled={isLoading}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MigrationBrandPage;
