import React, { FC } from 'react';
import './PopupBlurredVideos.scss';
import { useDispatch } from "react-redux";
import { getUnlimAccess } from "../../../redux/selectPlanSlice";

interface PopupBlurredVideosProps {
  children: React.ReactNode;
}

const PopupBlurredVideos: FC<PopupBlurredVideosProps> = ({children}) => {
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(getUnlimAccess('open-unlim-access'));
  }

  return (
    <div className="wrapperBlurredVideos">
      <div className="boxBlurredVideos">
        <div className="boxBlurringVideos" style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(223,223,223,0.5)',
          color: 'white',
          fontSize: '2rem',
          borderRadius: '10px',
          textAlign: 'center',
        }}>
              <div className="popUpBlurredVideos" style={{
                position: 'absolute',
                top: '50%', // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)', // Adjust for the dimensions of the box
                zIndex: 100,
                padding: '80px 60px 80px 60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255,255,255, 1)', // Semi-transparent dark overlay
                color: 'black',
                fontSize: '1.5rem',
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: 'normal',
                width: '75%',
              }}>
                <h2 style={{
                  fontSize: '4rem',
                  margin: 0,
                  fontWeight: 'normal',
                }}>ACCESS ALL 1,745 VIDEOS</h2>
                <p style={{
                  margin: 0,
                  marginBottom: '36px',
                  fontWeight: 'normal',
                  fontSize: '1rem',
                }}>
                  Get Unlimited access to full library & pro features from $8/mo
                </p>
                <button type="button" className="becomePro" onClick={handleOpen}>
                  Become Pro
                </button>
              </div>
            </div>
      </div>
      {children}
    </div>
  );
};

export default PopupBlurredVideos;
