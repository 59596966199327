import React from 'react';
import './CollectionName.scss';
import './MediaCollectionName.scss';
import ThumbCollectionWrapper from '../../components/ThumbWrapper/ThumbCollectionWrapper';
import UnloggedFooter from '../../components/Footer/UnlogFooter/UnlogFooter';
import { useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';

const CollectionName = () => {
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);

    return (
        <>
            <div className='collectionPage'>
                <div className='container'>
                    <div className="pageTitle">
                        <div className="collectionName">
                            <h2>
                                MY COLLECTIONS
                            </h2>
                        </div>
                    </div>
                    <div className='collection-page-wrapper'>
                        <ThumbCollectionWrapper/>
                        {guestStatus && <UnloggedFooter/>}
                    </div>
                </div>
            </div>
            {!guestStatus && <Footer/>}
        </>
    );
};

export default CollectionName;
