import React from 'react';
import google from '../../assets/images/google.svg';

import './Shared.scss';
import { usePostHog } from "posthog-js/react";

const ContGoogle = () => {
    const posthog = usePostHog();

    return (
      <>
          <div className="contGoogle" onClick={async () => {
            posthog.capture('Continue with google');
            window.location.href = `${process.env.REACT_APP_API_URL}/auth/google/auth`
          }}>
              <div className='googleImg'>
                  <img src={google} alt='google' />
              </div>
              <div className="btnDescription">
                    <span>
                        Continue with Google
                    </span>
              </div>
          </div>
      </>
    );
};

export default ContGoogle;
