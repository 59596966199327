import React, { FC } from 'react';

import './LinkCopied.scss';
import '../AllModalsStyle.scss';

interface LinkCopiedProps {
    textToDisplay: string;
}

const LinkCopied: FC<LinkCopiedProps> = ({textToDisplay}) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute'
      }}>
        <div className='linkCopied'>
            <span>{textToDisplay}</span>
        </div>
      </div>
    );
};

export default LinkCopied;
