import React from 'react';
import UnlogBrandFooter from '../../components/Footer/UnlogFooter/UnlogBrandFooter';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import './AdPage.scss';
import ThumbAdWrapper from "../../components/ThumbWrapper/ThumbAdWrapper";

const AdPage = () => {
  const guestStatus = useSelector((state: any) => state.logInState.guestStatus);


  return (
    <>
      {<div className="brandPage">
        <div className="container">
          <ThumbAdWrapper />
          {guestStatus && <UnlogBrandFooter/>}
        </div>
      </div>}
      {!guestStatus && <Footer />}
    </>
  );
};

export default AdPage;
