import './LogFooter.scss';
import '../MediaFooter.scss';
import React from 'react';
import { openSupportModal } from '../../../redux/supportSlice';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

const Footer = () => {
    const dispatch = useDispatch();

    return (
        <footer className='App-footer'>
            <div className='container'>
                <div className='footer-wrapper'>
                    <div className='top-footer'>
                        <div className='logoWrapper'>
                            <div className='logo'>
                                <div className='footerTitle'>
                                    <Link to='/' style={{
                                        textDecoration: 'none',
                                        color: 'black'
                                    }}>
                                        Adinspiration
                                    </Link>
                                </div>
                            </div>
                            <div className='logo-description'>
                                <span>The latest video ad inspiration in one place</span>
                            </div>
                        </div>
                        <div className='listWrapper'>
                            <div className='list'>
                                <span className='titleList'>Menu</span>
                                <ul>
                                    <li>
                                        <Link to="/" style={{
                                            textDecoration: 'none',
                                            color: '#9e9e9e'
                                        }}>
                                            Gallery
                                        </Link>
                                    </li>
                                    <li onClick={() => dispatch(openSupportModal())}>Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='bottom-footer'>
                        <div className='buttonWrapper left-buttonWrapper'>
                            <button>Adinspiration</button>
                            <div className='vLine'></div>
                            <button>Copyright 2024</button>
                        </div>
                        <div className='buttonWrapper'>
                            <button className='privacy'>
                                <Link to='/privacy' style={{
                                textDecoration: 'none',
                                color: 'black'
                            }}>Privacy Policy</Link>
                            </button>
                            <div className='vLine'></div>
                            <button>
                                <Link to="/terms" style={{
                                textDecoration: 'none',
                                color: 'black'
                            }}>
                                Terms of Service
                            </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
