import React, { useEffect, useState } from 'react';
import './ThumbWrapper.scss';
import './MediaThumbWrapper.scss';
import { useDispatch, useSelector } from 'react-redux';
import { adInspirationApi, useGetAdsFromBrandQuery } from "../../services/adinspiration-api";
import userProfileImg from "../../assets/images/user-avatar.png";
import { useParams } from "react-router-dom";
import ThumbAds from "../Thumb/ThumbAds";
import { setNumberAds } from "../../redux/rootSlice";

const ThumbWrapper = () => {
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const dispatch = useDispatch();
    const urlParam = useParams();

    const [page, setPage] = useState<number>(1);
    const { data, error, isLoading, isFetching } = useGetAdsFromBrandQuery({
        page: page,
        limit: 8,
        userId: urlParam?.id
    }, {
        refetchOnMountOrArgChange: true
    });

    dispatch(adInspirationApi.util.invalidateTags([{ type: 'Collection', id: urlParam?.id }]));
    dispatch(setNumberAds(data?.total || 0));


    useEffect(() => {
        // Can be better... TODO: refactor
        if (error) {
            return;
        }
        const onScroll = () => {
            if (data?.videos?.length === data?.total) {
                return;
            }
            const scrolledToBottom =
              ((window.innerHeight * .85) + (window.scrollY * .92)) >= document.body.offsetHeight * ((page * 0.25));
            // @ts-ignore
            if (scrolledToBottom && !isFetching && data.videos?.length < data.total) {
                setPage(page + 1);
            }
        };

        document.addEventListener("scroll", onScroll);

        return function () {
            document.removeEventListener("scroll", onScroll);
        };
    }, [page, isFetching, data?.total, data?.videos?.length, error]);


    if (error) {
        return <div>Something went wrong</div>
    }


    return (
      <div>
          {isLoading ? null :
            <div className="thumbWrapper">
                {data?.videos?.map((item) => {
                    if (guestStatus) {
                        return (
                          <div key={item._id}>
                              {item.playbackIds?.map((playbackId) => (
                                <ThumbAds
                                  key={playbackId.id}
                                  avatar={item.user.avatar || userProfileImg}
                                  userName={item.user.firstName}
                                  videoId={item._id}
                                  playbackId={playbackId.id}
                                  viewerId={'Guest'}
                                  videoTitle={item.title}
                                  userId={item.user._id}
                                  companyTypeP={item.user.companyType}
                                />
                              ))}
                          </div>
                        );
                    } else if (!guestStatus) {
                        return (
                          <div key={item._id}>
                              {item.playbackIds?.map((playbackId) => (
                                <ThumbAds
                                  key={playbackId.id}
                                  avatar={item.user.avatar || userProfileImg}
                                  userName={item.user.firstName}
                                  videoId={item._id}
                                  playbackId={playbackId.id}
                                  viewerId={item.user._id}
                                  videoTitle={item.title}
                                  userId={item.user._id}
                                  companyTypeP={item.user.companyType}
                                />
                              ))}
                          </div>
                        );
                    }
                    return false;
                })}

            </div>
          }
      </div>
    );
};

export default ThumbWrapper;
