import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popup } from '../../../redux/rootSlice';
import closeBtn from '../../../assets/images/closeBtn.svg';
import ContGoogle from '../../shared/ContGoogle';
import ChoiceMethod from '../../shared/ChoiceMethod';
import EnterEmail from '../../shared/EnterEmail';
import DefaultBtn from '../../shared/DefaultBtn';

import './SignIn.scss';
import '../AllModalsStyle.scss';
import { useLazyCheckEmailQuery } from "../../../services/adinspiration-api";
import { usePostHog } from "posthog-js/react";
const SignIn = () => {
    const posthog = usePostHog();
    const dispatch = useDispatch();
    const [triggerCheck] = useLazyCheckEmailQuery();
    const emailError = useSelector((state: any) => state.mainState.emailError);

    const emailValue = useSelector((state: any) => state.mainState.enterEmail);
    const checkEmail = async () => {
        try {
            await triggerCheck(emailValue).unwrap();
            posthog.identify(emailValue, {
                email: emailValue,
            })
            posthog.capture('Email entered', {
                email: emailValue,
            })
            dispatch(popup('confirm-signIn'));
        } catch (err) {
            dispatch(popup('email-error'));
        }
    }

    return (
        <div className='modal signIn'>
            <div className='modal-wrapper'>
                <div className='closeBtn' onClick={() => dispatch(popup('close-signIn'))}>
                    <img src={closeBtn} alt='close-btn' />
                </div>
                <div className='modalTitle'>
                    <h2>Join Adinspiration For Free</h2>
                </div>
                <ContGoogle />
                <ChoiceMethod />
                <EnterEmail />
                {
                    emailError ? <div className='errorText'>This email is invalid or is already taken</div> : null
                }
                <div onClick={checkEmail}>
                    <DefaultBtn textBtn="Continue with email" methodBtn="SignIn"/>
                </div>
                <div className="modalDescription">
                    <span>Already have an account?</span>

                    <span className='descriptionLink' onClick={() => dispatch(popup('goToLogInModal'))}>
                        Log In
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
