import React, { useEffect, useState } from 'react';
import './EditProfile.scss';
import backBtn from '../../assets/images/backBtn.svg';
import DefaultBtn from '../../components/shared/DefaultBtn';
import closeBtn from '../../assets/images/closeBtn.svg';
import { editPage } from '../../redux/editPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import downloadBtn from '../../assets/images/downloadBtn.svg';
import defaultUser from '../../assets/images/defaultUser.png';
import {
    setAvatar,
    setNewPassword,
    setOldPassword,
} from "../../redux/editUserSlice";
import { useUpdateUserMutation, useChangePasswordMutation } from "../../services/adinspiration-api";
import LinkCopied from "../../components/Modals/LinkCopied/LinkCopied";

const EditProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refAvatar, setRefAvatar] = React.useState<File>();
    const [updateProfile, {isLoading} ] = useUpdateUserMutation();
    const [updatePassword, {isLoading: isLoadingPassword} ] = useChangePasswordMutation();
    const user = useSelector((state: any) => state.userState.user);
    const oldPassword = useSelector((state: any) => state.editUserState.oldPassword);
    const newPassword = useSelector((state: any) => state.editUserState.newPassword);
    const [profileEdited, setProfileEdited] = useState(false);
    const [avatarUI, setAvatarUI] = useState<string | null>(user?.avatar ?? null);
    const [firstNameUI, setFirstNameUI] = useState<string>(user?.firstName ?? '');
    const [lastNameUI, setLastNameUI] = useState<string>(user?.lastName ?? '');
    const [emailUI, setEmailUI] = useState<string>(user?.email ?? '');
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);

    const execUpdateProfile = async () => {
        try {
            await updateProfile({
                firstName: firstNameUI,
                lastName: lastNameUI,
                email: emailUI,
                oldPassword,
                newPassword,
                avatar: refAvatar
            }).unwrap();
            //goBack();
            setProfileEdited(true);
            setTimeout(() => {
                setProfileEdited(false);
            }, 3000);
        } catch (error) {
            console.log(error);
        }
    }

    const execUpdatePassword = async () => {
        try {
            await updatePassword({
                oldPassword,
                newPassword
            }).unwrap();
        } catch (error) {
            console.log(error);
        }
    }

    const goBack = () => {
        dispatch(editPage('close-edit-page'));
        navigate(-1);
    };

    const redirectGuest = () => {
        if (guestStatus) {
            navigate('/login');
        }
    };

    useEffect(redirectGuest, [dispatch, guestStatus, navigate]);
    const fileInputRef: React.LegacyRef<HTMLInputElement> = React.createRef();

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null; // get the file
        if (file) {
            setRefAvatar(file);
            const url = URL.createObjectURL(file); // create a local URL for it
            setAvatarUI(url);
            dispatch(setAvatar(url)); // update the state with the file
        }
    };

    return (
        <>
            <div className='editProfile'>
                <div className='container'>
                    <div className='editPageWrapper'>
                        <div className='backBtn' onClick={() => goBack()}>
                            <img src={backBtn} alt='back-btn' />
                            <span>Back</span>
                        </div>
                        <div className='closeBtn' onClick={() => {}}>
                            <img src={closeBtn} alt='close-btn' />
                        </div>
                        <div className='modalTitle'>
                            <h2>ACCOUNT SETTINGS</h2>
                        </div>
                        <div className='formWrapper'>
                            <div className="rowWrapper leftWrapper">
                                <div className="userWrapperDiv">
                                    <div className="userAvatar">
                                        <img src={avatarUI ?? defaultUser} alt="user-avatar"/>
                                        <div className="avatarBtn" onClick={() => fileInputRef?.current?.click()}>
                                            <img src={downloadBtn} alt="download-btn"/>
                                            <input
                                              type="file"
                                              accept="image/*"
                                              style={{ display: 'none' }}
                                              ref={fileInputRef}
                                              onChange={handleImageUpload}
                                            />
                                            <span>Replace photo</span>
                                        </div>
                                    </div>

                                    <div className="inputItem">
                                        <div className="inputWrapper">
                                            <div className="titleWrapper">
                                                <h4>Email</h4>
                                                <div className="hoverBtn">i</div>
                                            </div>

                                            <input type="text" placeholder={emailUI ?? 'user.email@gmail.com'}
                                                   onChange={(e) => setEmailUI(e.target.value)}
                                                   value={emailUI ?? 'user.email@gmail.com'}/>
                                        </div>
                                        <div className="inputUserInfo">
                                            <div className="inputWrapper">
                                                <h4>First name</h4>
                                                <input type="text" placeholder={firstNameUI ?? 'Bohdan'}
                                                       onChange={(e) => setFirstNameUI(e.target.value)}
                                                       value={firstNameUI ?? 'Bohdan'}/>
                                            </div>
                                            <div className="inputWrapper">
                                                <h4>Last name</h4>
                                                <input type="text" placeholder={lastNameUI ?? 'Mukha'}
                                                       onChange={(e) => setLastNameUI(e.target.value)}
                                                       value={lastNameUI ?? 'Mukha'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    borderWidth: '1px',
                                    backgroundColor: "#f3f3f3",
                                    height: '2px',
                                    width: '100%',
                                }} className="separateLine">
                                    <br/>
                                </div>
                                <div className="passwordWrapper">
                                    <div className="inputWrapper">
                                        <h4>Old Password</h4>
                                        <input type="password" placeholder="********"
                                               onChange={(e) => dispatch(setOldPassword(e.target.value))}/>
                                    </div>
                                    <div className="inputWrapper">
                                        <h4>New Password</h4>
                                        <input type="password" placeholder="********"
                                               onChange={(e) => dispatch(setNewPassword(e.target.value))}/>
                                    </div>
                                    <div onClick={execUpdatePassword} className="defaultBtn">
                                        <DefaultBtn textBtn="Change" methodBtn="change-btn"
                                                    disabled={isLoadingPassword}/>
                                    </div>
                                </div>
                            </div>
                            <div className="rightWrapper">
                                <div className="buttonWrapper">
                                <div className='btn cancelBtn' onClick={() => goBack()}>
                                        <DefaultBtn textBtn='Cancel' methodBtn='cancel-progress' />
                                    </div>
                                    <div className="btn saveBtn" onClick={execUpdateProfile}>
                                        <DefaultBtn textBtn="Save" methodBtn="save-progress" disabled={isLoading}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {profileEdited && <LinkCopied textToDisplay="Settings updated"/>}
        </>
    );
};

export default EditProfile;
