import React, { useEffect, useState } from 'react';
import './CreateCollection.scss';
import DefaultBtn from '../../shared/DefaultBtn';
import closeBtn from '../../../assets/images/closeBtn.svg';

import { useDispatch, useSelector } from 'react-redux';
import { popupMethod } from '../../../redux/myCollection';
import {
    useCreateCollectionMutation,
    useGetCollectionQuery,
    useUpdateCollectionMutation,
} from "../../../services/adinspiration-api";
import { createdCollectionModal, enterNameOfCreatedCollection } from "../../../redux/rootSlice";
import { editCollection } from "../../../redux/editPageSlice";

interface CreateCollectionProps {
    collectionId?: string;
}

const CreateCollection = ({collectionId}: CreateCollectionProps) => {
    const [collectionName, setCollectionName] = useState<string>('');
    const [describeCollection, setDescribeCollection] = useState<string>('');
    const adToBeAdded = useSelector((state: any) => state.MyCollectionState.adToBeAdded);
    const dispatch = useDispatch();
    const [triggerCreateCollection] = useCreateCollectionMutation();
    const [triggerUpdateCollection] = useUpdateCollectionMutation();
    const {data, isLoading} = useGetCollectionQuery({collectionId: collectionId});

    const handleCreateClick = async () => {
        dispatch(enterNameOfCreatedCollection(collectionName));
        await triggerCreateCollection({name: collectionName, description: describeCollection, videos: adToBeAdded.id});
        dispatch(popupMethod('closeAllModal'));
        dispatch(createdCollectionModal('open-create-collection'));
        setTimeout(() => {
            dispatch(createdCollectionModal('close-create-collection'));
        }, 5000);
    }

    const handleUpdateClick = async () => {
        if (!collectionId) return;
        dispatch(enterNameOfCreatedCollection(collectionName));
        await triggerUpdateCollection({name: collectionName, description: describeCollection, collectionId: collectionId});
        dispatch(popupMethod('closeAllModal'));
        dispatch(editCollection('close-edit-collection'));
        dispatch(createdCollectionModal('open-create-collection'));
        setTimeout(() => {
            dispatch(createdCollectionModal('close-create-collection'));
        }, 5000);
    }

    useEffect(() => {
        if (collectionId && data && !isLoading) {
            setCollectionName(data.data.name || '');
            setDescribeCollection(data.data.description || '');
        }
    }, [collectionId, data, isLoading]);

    return (
        <>
            <div className='modal createCollection'>
                <div className='modal-wrapper'>
                    <div
                        className='closeBtn'
                        onClick={() => {
                            dispatch(popupMethod('closeAllModal'));
                            dispatch(editCollection('close-edit-collection'));
                        }}
                    >
                        <img src={closeBtn} alt='close-btn' />
                    </div>
                    <div className='modalTitle'>
                        {collectionId ? <h2>Edit collection</h2> : <h2>Create new collection</h2>}
                    </div>
                    <div className='formWrapper'>
                        <div className='formTitle'>
                            <h5>Collection name</h5>
                        </div>
                        <div className='collectionName'>
                            <input type='text' placeholder='Example: Sending Videos' value={collectionName} onChange={(e) => setCollectionName(e.target.value)}/>
                        </div>
                        <div className='describeCollection'>
                            <input name='describeCollection' placeholder='Describe your collection' value={describeCollection} onChange={(e) => setDescribeCollection(e.target.value)}></input>
                        </div>
                    </div>
                    <div className='buttonWrapper'>
                        <div className='cancelBtn'>
                            <DefaultBtn textBtn='Cancel' methodBtn='cancelBtn' />
                        </div>
                        <div className='saveBtn' onClick={collectionId ? handleUpdateClick : handleCreateClick}>
                            <DefaultBtn textBtn='Save' methodBtn={collectionId ? '' : 'saveCollection'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateCollection;
