import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import closeBtn from '../../../assets/images/closeBtn.svg';
import '../AllModalsStyle.scss';
import { forgotPassword, forgotPasswordSuccess } from "../../../redux/forgotPasswordSlice";
import EnterEmail from "../../shared/EnterEmail";
import DefaultBtn from "../../shared/DefaultBtn";
import { useForgetPasswordMutation } from "../../../services/adinspiration-api";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";



const ForgotPasswordModal = () => {

  const dispatch = useDispatch();
  const enteredEmail = useSelector((state: any) => state.mainState.enterEmail) as string;
  const [currentError, setCurrentError] = React.useState<string>();
  const [triggerForgotPassword, {isLoading, isError}] = useForgetPasswordMutation();


  return (
    <>
      <div className='modal login'>
        <div className='modal-wrapper'>
          <div className='closeBtn' onClick={() => dispatch(forgotPassword('close-forgot-password'))}>
            <img src={closeBtn} alt='close-btn'/>
          </div>
          <div className='modalTitle'>
            <h2>Reset your password</h2>
            <EnterEmail/>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              height: '6px',
              color: 'red',
              fontFamily: 'var(--Inter)'
            }}>
              {isError && <div className='error'>{currentError}</div>}
            </div>
          </div>
          <div onClick={async () => {
            try {
              await triggerForgotPassword({ email: enteredEmail }).unwrap();
              dispatch(forgotPassword('close-forgot-password'));
              dispatch(forgotPasswordSuccess('open-forgot-password-success'));
            } catch (err: any) {
              if (err satisfies FetchBaseQueryError)
                setCurrentError(err.data.error ?? 'Email not found');
              return;
            }
          }}>
            <DefaultBtn textBtn='Reset password' methodBtn={'ResetPassword'} disabled={isLoading}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordModal;

