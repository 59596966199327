import closeBtn from '../../../assets/images/closeBtn.svg';
import '../AllModalsStyle.scss';
import { forgotPasswordSuccess } from "../../../redux/forgotPasswordSlice";
import { useDispatch, useSelector } from "react-redux";

const ForgotPasswordSucessModal = () => {
  const dispatch = useDispatch();
  const enteredEmail = useSelector((state: any) => state.mainState.enterEmail) as string;
  return (
    <>
      <div className='modal login'>
        <div className='modal-wrapper'>
          <div className='closeBtn' onClick={() => dispatch(forgotPasswordSuccess('close-forgot-password-success'))}>
            <img src={closeBtn} alt='close-btn'/>
          </div>
          <div className='modalTitle'>
            <h2>Check your email</h2>
            <p style={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'var(--Inter)',
              color: 'var(--grey)'
            }}>
              We have sent an email to {enteredEmail} Please follow the instructions sent in the email to reset your password.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordSucessModal;

