import React from 'react';
import { popupMethod } from "../../../redux/myCollection";
import {
  useAddVideoToCollectionMutation,
  useGetCollectionsIdsAndNamesFromUserQuery,
} from "../../../services/adinspiration-api";
import { useDispatch, useSelector } from "react-redux";
import { createdCollectionModal, enterNameOfCreatedCollection } from "../../../redux/rootSlice";
import { ICollectionIdsAndNames } from "../../../model/collection";

const CollectionItems = () => {
  const {data, isLoading} = useGetCollectionsIdsAndNamesFromUserQuery();
  const adToBeAdded = useSelector((state: any) => state.MyCollectionState.adToBeAdded);
  const [triggerAddVideo] = useAddVideoToCollectionMutation();
  const dispatch = useDispatch();
  async function handleSavedAd(collection: ICollectionIdsAndNames): Promise<void> {
    dispatch(enterNameOfCreatedCollection(collection.name));
    dispatch(createdCollectionModal('open-create-collection'));

    setTimeout(() => {
      dispatch(createdCollectionModal('close-create-collection'));
    }, 5000);
  }

  if (data?.data.length === 0) {
    dispatch(popupMethod('createCollection'));
  }

  return (
    <>
      {isLoading ? <div>Loading...</div> : data?.data.map(collection => {
        return (
          <div className="collectionWrapper" key={collection._id}>
            <div className="collectionImages">
              <div className="images">
                <div className="image" style={{ opacity: '0.35' }}></div>
                <div className="image" style={{ opacity: '0.6', left: '12px' }}></div>
                <div className="image" style={{ opacity: '1', left: '24px' }}></div>
              </div>
              <div className="collectionTitle">
                <h5>{collection.name}</h5>
                <span>{collection.videoLength}</span>
              </div>
            </div>
            <div className="collectionBtn">
              <div
                className="btn copyLinkBtn"
                onClick={async () => {
                  await handleSavedAd(collection);
                  await triggerAddVideo({collectionId: collection._id, videoId: adToBeAdded.id});
                  dispatch(popupMethod('closeAllModal'));
                }}
              >
                Save
              </div>
              <div
                className="btn deleteBtn"
                onClick={() => {
                  dispatch(popupMethod('removeCollection'));
                }}
              >
                Delete
              </div>
            </div>
          </div>
        )
      })}
    </>
  );
};

export default CollectionItems;
