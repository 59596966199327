import React from 'react';
import ThumbBrandWrapper from '../../components/ThumbWrapper/ThumbBrandWrapper';
import UnlogBrandFooter from '../../components/Footer/UnlogFooter/UnlogBrandFooter';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import DefaultBtn from '../../components/shared/DefaultBtn';
import './BrandPage.scss';
import { useGetUserQuery } from "../../services/adinspiration-api";
import { useParams } from "react-router-dom";
import creatorData from "../../data/creatorData";

const BrandPage = () => {
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const urlParam = useParams();
    const totalAds = useSelector((state: any) => state.mainState.numberAds);


    const {data, isLoading, error} = useGetUserQuery(urlParam?.id, {
        refetchOnMountOrArgChange: true
    });



    if (error) {
        return <div>Something went wrong...</div>
    }

    return (
        <>
            {isLoading ? <p>Loading...</p> : <div className="brandPage">
                <div className="container">
                    <div className="brandContent">
                        <div className="brandDescription">
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                marginBottom: '1rem',
                            }}>
                                <img src={data?.user.avatar ?? creatorData.creatorImg} alt="brand-avatar"
                                     className="creatorAvatarImage"
                                     style={{
                                         width: '64px',
                                         height: '64px',
                                         objectFit: 'cover',
                                         borderRadius: '50%',
                                     }}/>
                                <div>
                                    <div className="brand">
                                        <span>{data?.user.category ?? 'Other'}</span>
                                    </div>
                                    <div className="brandName">
                                        <h2>{data?.user.firstName ?? 'Brand'}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="brandMessage">
                                <p>{`${totalAds}`} top {`${data?.user.firstName}`} ads: Get Inspired with the best
                                    Facebook, Instagram, & TikTok ad video creatives</p>
                            </div>
                            <a href={`https://${data?.user.website}`} rel="noreferrer" target="_blank">
                                <DefaultBtn textBtn="Our website" methodBtn="our-website"/>
                            </a>
                        </div>
                    </div>
                    <ThumbBrandWrapper/>
                    {guestStatus && <UnlogBrandFooter/>}
                </div>
            </div>}
            {!guestStatus && <Footer />}
        </>
    );
};

export default BrandPage;
