import React, { useEffect } from 'react';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import ThumbWrapper from '../../components/ThumbWrapper/ThumbWrapper';
import './MainPage.scss';
import './MediaMainPage.scss';
import Footer from '../../components/Footer/Footer';

import YourCollections from '../../components/Modals/YourCollections/YourCollections';
import SuccessSendReq from '../../components/Modals/SuccessSupportReq/SuccessSendReq';

import { useSelector, useDispatch } from 'react-redux';
import { paymentDetails } from '../../redux/selectPlanSlice';
import ForgotPasswordModal from "../../components/Modals/ForgotPassword/ForgotPasswordModal";
import ForgotPasswordSucessModal from "../../components/Modals/ForgotPasswordSucess/ForgotPasswordSucessModal";

const MainPage = () => {
    const yourCollectionState = useSelector((state: any) => state.MyCollectionState.yourCollectionModal);
    const sendRequestModal = useSelector((state: any) => state.supportState.sendRequestModal);
    const forgotPasswordModal = useSelector((state: any) => state.forgotPasswordState.forgotPasswordModal);
    const forgotPasswordSuccess = useSelector((state: any) => state.forgotPasswordState.forgotPasswordSuccessModal);
    const user = useSelector((state: any) => state.userState.user);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paymentDetails('close-payment-details'));
    }, [dispatch]);


    return (
        <>
            {yourCollectionState && <YourCollections />}
            {sendRequestModal && <SuccessSendReq />}
            {forgotPasswordModal && <ForgotPasswordModal />}
            {forgotPasswordSuccess && <ForgotPasswordSucessModal />}
            <div className='main-page'>
                <div className='container'>
                    <div className='main-page-wrapper'>
                        {!user && (
                            <div className='main-page-banner'>
                                <h1 className='main-page-banner-title'>
                                    The latest
                                    <br />
                                    <span className='main-page-banner-title__second-line'>video ad hooks</span>
                                    <br />
                                    <span className='main-page-banner-title__third-line'>in one place</span>
                                </h1>
                                <span className='main-page-banner-socials'>Facebook, Instagram & TikTok</span>

                                <span className='main-page-banner-videos'>UGC Short-form Videos</span>

                                <span className='main-page-banner-brands'>
                                    Discover the top brands and creators
                                    <br /> through their creatives
                                </span>
                            </div>
                        )}
                        <SearchFilters />
                        <ThumbWrapper />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MainPage;
