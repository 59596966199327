import './LandingPro.scss';
import { useSelector } from "react-redux";
import { useCheckoutMutation } from "../../services/adinspiration-api";

const LandingPro = () => {
  const userIsGuest = useSelector((state: any) => state.logInState.guestStatus);
  const [triggerCheckout] = useCheckoutMutation();
  const handleSignUpPro = () => {
    window.location.href = `https://buy.stripe.com/28o6qn2xW3jKbniaEE`
  }

  const handleSignUpProIsUser = async () => {
    try {
      // Reload page
      const {url} = await triggerCheckout().unwrap();
      window.location.href = `${url}`
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className='planWrapperLanding'>
        <h1 className="titleLanding">GET UNLIMITED ACCESS</h1>
        <h3>Save 1,000+ hours of research and boost you ROAS</h3>
        <div className={`planLanding proLanding activePlanLanding`}>
          <div className='planTypeLanding'>
            <div className='costLanding'>
              <h2>Pro</h2>
            </div>
            <div className='costLanding'>
              $8/mo
              <span>Billed annually</span>
            </div>
          </div>
          <div className='listLanding'>
            <ul>
              <li>
                <span>Unlimited access to ads</span>
              </li>
              <li>
                <span>Unlimited search</span>
              </li>
              <li>
                <span>Save favourites</span>
              </li>
              <li>
                <span>Unlimited collections</span>
              </li>
              <li>
                <span>Shared collections</span>
              </li>
            </ul>
          </div>
          <div className="planBtnLanding" onClick={userIsGuest ? handleSignUpPro : handleSignUpProIsUser}>
            <button className="proButtonLanding">Go Pro</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPro;
