import React, { useEffect, useState } from 'react';
import './CreatorPage.scss';
import creator from '../../assets/images/creator.svg';
import clapperboard from '../../assets/images/clapperboard.svg';
import creatorData from '../../data/creatorData';
import tikTok from '../../assets/images/tiktok.svg';
import inst from '../../assets/images/instagram.svg';
import xSocial from '../../assets/images/x.svg';
import globe from '../../assets/images/globe.svg';

//swiper
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import UnlogBrandFooter from '../../components/Footer/UnlogFooter/UnlogBrandFooter';
import Footer from '../../components/Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import DefaultBtn from '../../components/shared/DefaultBtn';
import GetUnlimAccess from '../../components/Modals/SelectPlanModal/GetUnlimAccess';
import SupportModal from '../../components/Modals/SupportModal/SupportModal';
import SuccessSendReq from '../../components/Modals/SuccessSupportReq/SuccessSendReq';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
//import { popup } from '../../redux/rootSlice';
import {
    adInspirationApi,
    Category,
    useGetCountCategoriesQuery,
    useGetUserQuery,
} from "../../services/adinspiration-api";
import { paymentDetails } from "../../redux/selectPlanSlice";
import { popup } from "../../redux/rootSlice";

const CreatorPage = () => {
    const dispatch = useDispatch();
    const urlParam = useParams();
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const getUnlimAccessModal = useSelector((state: any) => state.selectPlanState.getUnlimAccessModal);
    const supportModal = useSelector((state: any) => state.supportState.supportModal);
    const sendRequestModal = useSelector((state: any) => state.supportState.sendRequestModal);
    const currentLoggedIdUser = useSelector((state: any) => state.userState.user?.id);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('category');
    const navigate = useNavigate();

    const [activeClass, setActiveClass] = useState<string>('ads');
    //const filterPopup = useSelector((state: any) => state.mainState.filterPopup);

    const {data, isLoading, error} = useGetUserQuery(urlParam?.id, {
        refetchOnMountOrArgChange: true,
    });
    const { data: categoriesData } = useGetCountCategoriesQuery(urlParam?.id, {
        refetchOnMountOrArgChange: true,
    });

    dispatch(adInspirationApi.util.invalidateTags(['AdsFromUser']));

    useEffect(() => {
        dispatch(paymentDetails('close-payment-details'));
    }, [dispatch]);


    if (isLoading) return null;

    /*const callModal = (btn: string) => {
        const toggleFilter = () => {
            filterPopup ? dispatch(popup('close-filter')) : dispatch(popup('open-filter'));
        };

        switch (btn) {
            case 'filter':
                guestStatus ? dispatch(popup('open-signIn')) : toggleFilter();

                break;
            case 'search':
                guestStatus ? dispatch(popup('open-signIn')) : navigate('/search-page');

                break;
        }
    };*/

    const activeCategories = categoriesData?.categories?.map((c: { category: any; }) => c.category) || [];

    // Function to check if the category is active based on categoriesData
    const isCategoryActive = (categoryValue: Category) => activeCategories.includes(categoryValue);

    return (
        <>
            {getUnlimAccessModal && <GetUnlimAccess />}
            {supportModal && <SupportModal />}
            {sendRequestModal && <SuccessSendReq />}


            <div className='creatorPage'>
                <div className='container'>
                    {error ? <h2>User doesn't exists</h2> :<div className="creatorContent">
                        <div className="creatorAvatar">
                            <img src={data?.user.avatar ?? creatorData.creatorImg} alt="creator-avatar"
                                 className="creatorAvatarImage" style={{
                                width: '200px',
                                height: '200px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                            }}/>
                        </div>
                        <div className="creatorDescription">
                            <div className="creatorName">
                                <h2>{data?.user.firstName} {data?.user.lastName}</h2>
                                {
                                    currentLoggedIdUser === urlParam?.id ? <DefaultBtn textBtn="Edit" methodBtn="open-edit-page"/> : null
                                }
                            </div>
                            <div className="creatorItems">
                                <div className="item creator">
                                    <img src={creator} alt="creator"/>
                                    <span>{data?.user.companyType}</span>
                                </div>
                                {data?.user.city ? <div className="item location">
                                    <img src={creator} alt="location"/>
                                    <span>{`${data?.user.city}${data?.user?.country ? ',' : ''} ${data?.user.country ?? ''}` ?? ''}</span>
                                </div> : null}
                                {data?.user.rate ? <div className="item media location">
                                    <img src={clapperboard} alt="clapperboard"/>
                                    <span>${data?.user.rate ?? null}+</span>
                                </div> : null}
                            </div>
                            <div className="creatorMessage">
                                {`${data?.user.description ?? ''}`}
                            </div>
                            <div className="socials">
                                {data?.user.tiktok ? <div className="imgWrapper">
                                    <a href={data?.user.tiktok ?? 'https://www.tiktok.com/'} target="_blank"
                                       rel="noreferrer">
                                        <img src={tikTok} alt="tik-tok"/>
                                    </a>
                                </div> : null}
                                {data?.user.instagram ? <div className="imgWrapper">
                                    <a href={data?.user.instagram ?? 'https://www.instagram.com/'} target="_blank"
                                       rel="noreferrer">
                                        <img src={inst} alt="inst"/>
                                    </a>
                                </div> : null}
                                {data?.user.twitter ? <div className="imgWrapper">
                                    <a href={data?.user.twitter ?? 'https://www.twitter.com/'} target="_blank"
                                       rel="noreferrer">
                                        <img src={xSocial} alt="x-social"/>
                                    </a>
                                </div> : null}
                                {data?.user.website ? <div className="imgWrapper">
                                    <a href={data?.user.website} target="_blank" rel="noreferrer">
                                        <img src={globe} alt="world"/>
                                    </a>
                                </div> : null}
                                {/* <div className="workBtn"> */}
                              <a href={`mailto:${data?.user.email}`} referrerPolicy="no-referrer" target="_blank" rel="noreferrer">
                                <DefaultBtn textBtn="Work with me" methodBtn="work-with-me"/>
                              </a>
                                {/* </div> */}
                            </div>
                            <div className="mobileBtn">
                                <div className="uploadBtn">
                                    <DefaultBtn textBtn="Upload ad +" methodBtn="upload-video"/>
                                </div>
                                <div className="editBtn">
                                    <DefaultBtn textBtn="Edit" methodBtn="open-edit-page"/>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className='creator-tags-block'>
                            <div className='creator-menu'>
                                <ul>
                                    <li className={activeClass === 'ads' ? 'active' : ''}>
                                        <Link
                                            to={`/creator-page/ads/${data?.user._id}`}
                                            onClick={() => {
                                                setActiveClass('ads');
                                            }}
                                        >
                                            <span>My Work</span>
                                        </Link>
                                    </li>
                                    <li className={activeClass === 'collections' ? 'active' : ''}>
                                        <Link
                                            to={`/creator-page/collections/${data?.user._id}`}
                                            onClick={() => {
                                                setActiveClass('collections');
                                            }}
                                        >
                                            <span>Collections</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                        <Swiper slidesPerView='auto' spaceBetween={10} navigation={true} modules={[Navigation]} className='mySwiper'>
                            {Object.entries(Category).map(([key, value]) => {
                                if (!isCategoryActive(value)) return null; // Skip rendering if category is not active

                                const toLink = `/creator-page/ads/${urlParam?.id}?category=${value}`;

                                return (
                                  <SwiperSlide key={key}>
                                      <Link
                                        to='#'
                                        className={"swiper-link" + (category === value ? ' active' : '')}
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent the default link action
                                            if (guestStatus) {
                                                dispatch(popup('open-signIn'));
                                            } else if (category === value) {
                                                navigate(`/creator-page/ads/${urlParam?.id}`); // Navigate to homepage if the category is already selected
                                            } else {
                                                navigate(toLink); // Navigate to the selected category
                                            }
                                        }}
                                      >
                                          {value}
                                      </Link>
                                  </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>

                    {!error && <Outlet/>}
                    {guestStatus && <UnlogBrandFooter />}
                </div>
            </div>
            {!guestStatus && <Footer />}
        </>
    );
};

export default CreatorPage;
