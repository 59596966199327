import React from 'react';
import EnterEmail from '../../components/shared/EnterEmail';
import EnterPass from '../../components/shared/EnterPass';
import DefaultBtn from '../../components/shared/DefaultBtn';

import './AdminLogin.scss';
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../../services/adinspiration-api";
import { popup } from "../../redux/rootSlice";
import { adminLogIn } from "../../redux/adminSlice";
import { useNavigate } from "react-router-dom";
import { RoleType } from "../../model/creatorModel";

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [login] = useLoginMutation();

    const enterEmail = useSelector((state: any) => state.mainState.enterEmail);

    const enterPassword = useSelector((state: any) => state.mainState.enterPassword);

    const handleSubmit = async () => {
        try {
            const payload = await login({ email: enterEmail, password: enterPassword }).unwrap();
            if (payload.user.role.type === RoleType.Admin) {
                dispatch(adminLogIn(true));
                navigate('/admin');
            } else {
                dispatch(popup('email-error'));
                dispatch(popup('password-error'));
                navigate('/login')
            }
        } catch (err) {
            dispatch(popup('email-error'));
            dispatch(popup('password-error'));
        }
    }

    return (
        <>
            <div className='adminLogin'>
                <div className='adminLoginWrapper'>
                    <h1 className='adminLoginTitle'>
                        Log in to
                        <br /> Admin dashboard
                    </h1>
                    <div className='adminLoginForm'>
                        <form onSubmit={async (e) => {
                            e.preventDefault();
                            await handleSubmit();
                        }}>
                            <EnterEmail />
                            <EnterPass />

                            <DefaultBtn textBtn='Log in' methodBtn={'AdminLogin'}></DefaultBtn>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;
