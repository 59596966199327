import { useState } from 'react';
import './CollectionName.scss';
import './MediaCollectionName.scss';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import ThumbsAdsFromCollection from "../../components/ThumbWrapper/ThumbAdsFromCollection";
import {
  adInspirationApi,
  useDeleteCollectionMutation,
  useGetAdsFromCollectionQuery,
} from "../../services/adinspiration-api";
import { useNavigate, useParams } from "react-router-dom";
import { copiedLinkState } from "../../redux/rootSlice";
import UnlogBrandFooter from "../../components/Footer/UnlogFooter/UnlogBrandFooter";
import CopyImage from '../../assets/images/copyLinkBlack.svg';
import Pencil from '../../assets/images/PencilCollection.svg';
import Delete from '../../assets/images/DeleteCollection.svg';
import CreateCollection from "../../components/Modals/CreateCollection/CreateCollection";
import { editCollection } from "../../redux/editPageSlice";

const CollectionVideosPage = () => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const { data, error, isLoading } = useGetAdsFromCollectionQuery({
    collectionId:  urlParam?.id,
  }, {
    refetchOnMountOrArgChange: true,
  });
  dispatch(adInspirationApi.util.invalidateTags([{ type: 'Collection', id: urlParam?.id }]));

  const editCollectionState = useSelector((state: any) => state.editPageState.editCollection);
  const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
  const userState = useSelector((state: any) => state.userState.user);
  const [chooseTypeModal, setSchooseTypeModal] = useState(false);
  const [triggerDeleteCollection] = useDeleteCollectionMutation();
  const navigate = useNavigate();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong...</div>;
  }

  return (
    <>
      <div className='collectionPage'>
        <div className='container'>
          <div className='collection-page-wrapper'>
            <div className="collectionTitle">
              <div className="collectionName">
                <span className="userAvatar">
                  <img src={data?.user.avatar} alt="" style={{
                    borderRadius: '50%',
                    width: '64px',
                    height: '64px',
                  }}/>
                </span>
                <span className="name">{data?.user.firstName}</span>
              </div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '14px',
                justifyItems: 'center',
              }}>
                <h2 className="pageTitle">{data?.name}</h2>
                <button className="copyLinkBtn" type="button" onClick={() => {
                  dispatch(copiedLinkState('copied-modal-open'));
                  navigator.clipboard.writeText(window.location.href);
                  setTimeout(() => {
                    dispatch(copiedLinkState('copied-modal-close'));
                  }, 5000);
                }}>
                  <img src={CopyImage} alt="copy link"/>
                  <span>Copy</span>
                </button>
                {userState?.id === data?.user._id ? <button className="copyLinkBtn companyInput" type="button" onClick={() => {
                  setSchooseTypeModal(!chooseTypeModal);
                }}>
                  <span>...</span>
                  {chooseTypeModal && (
                    <div className="companyType">
                      <div>
                        <ul>
                          {/*<li
                            onClick={() => {
                              setSchooseTypeModal(!chooseTypeModal);
                              console.log('Download all screens');
                            }}
                          >
                            <img src={Download} alt="Download all screens"/>
                            <span>Download all screens</span> <span className="proSpan">PRO</span>
                          </li> */}
                          <li
                            onClick={() => {
                              setSchooseTypeModal(!chooseTypeModal);
                              dispatch(editCollection('open-edit-collection'));
                            }}
                          >
                            <img src={Pencil} alt="Edit collection"/>
                            <span>Edit collection</span>
                          </li>
                          <li
                            onClick={() => {
                              setSchooseTypeModal(!chooseTypeModal);
                              triggerDeleteCollection({ collectionId: urlParam?.id });
                              navigate('/');
                            }}
                          >
                            <img src={Delete} alt="Delete"/>
                            <span style={{
                              color: 'red',
                            }}>Delete</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </button> : null}
              </div>
              <p className="pageDescription" style={{
                fontSize: '20px',
                opacity: '0.6',
              }}>
                This collection is specially curated by {data?.user.firstName} from Adinspiration gallery
              </p>
            </div>
            <ThumbsAdsFromCollection/>
            {guestStatus && <UnlogBrandFooter/>}
          </div>
        </div>
      </div>
      {editCollectionState && <CreateCollection collectionId={urlParam?.id}/>}
      {!guestStatus && <Footer/>}
    </>
  );
};

export default CollectionVideosPage;
