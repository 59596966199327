import React from 'react';
import './Thumb.scss';
import { creatorBrand } from '../../redux/rootSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ICollectionData, IPlaybackIds } from "../../model/collection";
// import thumb-1 from '../../assets/images/thumb-1.png'

interface ThumbCollectionModel {
    collectionData: ICollectionData;
}

export default function ThumbCollection({ collectionData }: ThumbCollectionModel) {
    const dispatch = useDispatch();

    return (
        <div className='thumbItemWrapper'>
            <Link to={`/collection-page/${collectionData._id}`}>
                <div
                  className='thumbCollectionItem'
                  onClick={() => {
                      dispatch(creatorBrand('creator'));
                  }}
                >
                    <div className='collectionNameBlock'>
                        <div className='collectionName' style={{
                            fontSize: "14px",
                            fontWeight: "bold"
                        }}>
                            {collectionData.name}
                        </div>
                    </div>
                    <div className='thumbCollectionImg'>
                        {
                            collectionData.videos.map((video) => {
                                return video.playbackIds.map((playbackId: IPlaybackIds) => {
                                    return (
                                      <img
                                        src={`https://image.mux.com/${playbackId.id}/thumbnail.png`}
                                        alt='thumb'
                                        key={playbackId.id}
                                      />
                                    );
                                })
                            })
                        }
                    </div>
                </div>
            </Link>
        </div>
    );
}
