import React, { useEffect, useRef, useState } from "react";
import './ThumbWrapper.scss'
import './MediaThumbWrapper.scss'
import Thumb from "../Thumb/Thumb";
import { useSelector } from "react-redux";
import userProfileImg from "../../assets/images/user-avatar.png";
import { Category, useGetAdsQuery } from "../../services/adinspiration-api";
import { useLocation } from "react-router-dom";
import PopupBlurredVideos from "../Modals/PopupBlurredVideos/PopupBlurredVideos";
import BlurredVideos from "../Modals/BlurredVideos/BlurredVideos";
import { WindowScroller, AutoSizer, Grid } from 'react-virtualized';

const ThumbWrapper = () => {
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const location = useLocation();
    const prevCategoryRef = useRef<string>('');
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('category');
    const userFree = useSelector((state: any) => state.logInState.userFree);
    const [columnCount, setColumnCount] = useState(4);
    const [itemSize, setItemSize] = useState(500); // You can adjust this based on your responsive design
    const [forReRender, setForReRender] = useState<number>(0);
    const validateCategory = (query: string | null): Category | undefined => {
        return Object.values(Category).find((value) => value === query) ?? undefined;
    };

    const [page, setPage] = useState<number>(1);

    const { data, error, isLoading, isFetching} = useGetAdsQuery({
        page: page,
        limit: guestStatus ? 12 : 4,
        category: validateCategory(category),
    }, {
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        const breakpoints = [
            { width: 354, columns: 1, size: 565 },
            { width: 412, columns: 1, size: 664 },
            { width: 446, columns: 1, size: 725 },
            { width: 474, columns: 1, size: 775 },
            { width: 512, columns: 1, size: 815 },
            { width: 564, columns: 2, size: 500 },
            { width: 642, columns: 2, size: 550 },
            { width: 764, columns: 3, size: 475 },
            { width: 842, columns: 3, size: 500 },
            { width: 1024, columns: 3, size: 600 },
            { width: 1368, columns: 3, size: 715 },
            { width: Infinity, columns: 4, size: 550 } // Handles all larger sizes
        ];

        const updateGrid = () => {
            setForReRender(Date.now()); // This is a trick to force the grid to re-render
            const width = window.innerWidth;

            // Find the first breakpoint that is greater than or equal to the window width
            const breakpoint = breakpoints.find(b => width < b.width) || breakpoints[breakpoints.length - 1];

            setColumnCount(breakpoint.columns);
            setItemSize(breakpoint.size);
        };

        updateGrid(); // Initial call to set grid based on current width

        window.addEventListener('resize', updateGrid); // Adjust on resize

        return () => window.removeEventListener('resize', updateGrid);
    }, []); // Empty dependency array means this runs on mount and unmount

    useEffect(() => {
        // Can be better... TODO: refactor
        if (error) {
            return;
        }
        const onScroll = () => {
            if (data?.videos?.length === data?.total) {
                return;
            }

            let scrolledToBottom;

            if (userFree) {
                scrolledToBottom =
                  ((window.innerHeight * .85) + (window.scrollY * .92)) >= document.body.offsetHeight * ((page * .50));
            } else {
                scrolledToBottom = ((window.innerHeight * 1.15) + (window.scrollY)) >= document.body.scrollHeight;
            }
            // @ts-ignore
            if (scrolledToBottom && !isFetching && data.videos?.length < data.total) {
                setPage(page + 1);
            }
        };

        document.addEventListener("scroll", onScroll);

        return function () {
            document.removeEventListener("scroll", onScroll);
        };
    }, [page, isFetching, data?.total, data?.videos?.length, error]);

    useEffect(() => {
        // Check if category has changed
        if (prevCategoryRef.current !== category) {
            setPage(1); // Reset to the first page
            prevCategoryRef.current = category || '';
        }
    }, [category]);


    const cellRenderer = ({ columnIndex, key, rowIndex, style }: {columnIndex: number, key: any, rowIndex: number, style: React.CSSProperties}) => {
        const index = rowIndex * columnCount + columnIndex;
        const item = data?.videos && data.videos[index];

        // Out of range items should not be rendered
        if (!item) return null;

        return (
          <div key={key} style={style}>
              {/* Render your item component */}
              <Thumb
                key={item._id}
                avatar={item.user.avatar || userProfileImg}
                userName={item.user.firstName}
                videoId={item._id}
                playbackId={item.playbackIds?.[0]?.id}
                viewerId={guestStatus ? 'Guest' : item.user._id}
                videoTitle={item.title}
                userId={item.user._id}
                companyTypeP={item.user.companyType}
                originalLink={item.originalLink}
              />
          </div>
        );
    };

    if (error) {
        return <div>Something went wrong</div>
    }

    return (
      <>
          {isLoading ? <div>Loading...</div> :
            <>
                <WindowScroller>
                    {/* @ts-ignore */}
                    {({ height, isScrolling, onChildScroll, scrollTop }) => (
                      <AutoSizer disableHeight>
                          {/* @ts-ignore */}
                          {({ width }) => (
                            <Grid
                              key={forReRender}
                              autoHeight
                              height={height}
                              width={width}
                              columnWidth={(width) / columnCount}
                              columnCount={columnCount}
                              rowHeight={itemSize}
                              // @ts-ignore
                              rowCount={Math.ceil(data?.videos?.length / columnCount) || 0}
                              isScrolling={isScrolling}
                              onScroll={(scroll) => {
                                  scroll.scrollTop += 5;
                                  scroll.scrollTop -= 5;
                              }}
                              scrollTop={scrollTop}
                              cellRenderer={cellRenderer}
                            />
                          )}
                      </AutoSizer>
                    )}
                </WindowScroller>
                {userFree &&
                  <PopupBlurredVideos>
                    <div className="thumbWrapper">
                        <BlurredVideos/>
                    </div>
                </PopupBlurredVideos>}
            </>
          }
      </>
    );
}

export default ThumbWrapper;
