import React from 'react';
import './TosPage.scss';

const TosPage = () => {
  return (
    <div className="container">
      <h3 style={{
        textAlign: 'left',
        margin: '20px 20px'
      }}>
        Last updated on February 1st. 2024
      </h3>
      <h2>TERMS OF SERVICE</h2>
      <article>
        <section className="tos-text">
          Welcome to adinspiration.com, the website and online service of 23creative LLC (“Adinspiration,” “we,” or
          “us”). This page explains the terms by which you may use our online services, website, and software provided
          on or in connection with the service (collectively the “Service”). By accessing or using the Service, you
          signify that you have read, understood, and agree to be bound by this Terms of Service Agreement (“Agreement”)
          and to the collection and use of your information as set forth in the Adinspiration Privacy Policy
          (<a href="/privacy" referrerPolicy="no-referrer" target="_blank">https://www.adinspiration.com/privacy</a>), whether or not you are a registered user of our Service. This
          Agreement applies to all visitors, users, and others who access the Service (“Users”)
        </section>
        <section className="tos-text">
          PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT CONTAINS A
          MANDATORY ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO
          RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
        </section>
        <section className="tos-text">
          1.Use of Our Service
        </section>
        <section className="tos-text">
          1.A. Eligibility
          <br/>
          <br/>
          You may use the Service only if you can form a binding contract with Adinspiration, and only in compliance
          with this Agreement and all applicable local, state, national, and international laws, rules and regulations.
          Any use or access to the Service by anyone under 13 is strictly prohibited and in violation of this Agreement.
          The Service is not available to any Users previously removed from the Service by Adinspiration.

        </section>
        <section className="tos-text">
          1.B. Adinspiration Service.
          <br/>
          <br/>
          Subject to the terms and conditions of this Agreement, you are hereby granted a non-exclusive, limited,
          non-transferable, freely revocable license to use the Service as permitted by the features of the Service.
          Adinspiration reserves all rights not expressly granted herein in the Service and the Adinspiration Content
          (as defined below). Adinspiration may terminate this license at any time for any reason or no reason.
        </section>

        <section className="tos-text">
          1.D. Service Rules
          <br/>
          <br/>
          You agree not to engage in any of the following prohibited activities: (i) copying, distributing, or
          disclosing any part of the Service in any medium, including without limitation by any automated or
          non-automated “scraping”; (ii) using any automated system, including without limitation “robots,” “spiders,”
          “offline readers,” etc., to access the Service in a manner that sends more request messages to the
          Adinspiration servers than a human can reasonably produce in the same period of time by using a conventional
          on-line web browser (iii) transmitting spam, chain letters, or other unsolicited email; (iv) attempting to
          interfere with, compromise the system integrity or security or decipher any transmissions to or from the
          servers running the Service; (v) taking any action that imposes, or may impose at our sole discretion an
          unreasonable or disproportionately large load on our infrastructure; (vi) uploading invalid data, viruses,
          worms, or other software agents through the Service; (vii) collecting or harvesting any personally
          identifiable information, including account names, from the Service; (viii) using the Service for any
          commercial solicitation purposes; (ix) impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity; (x)
          interfering with the proper working of the Service; (xi) accessing any content on the Service through any
          technology or means other than those provided or authorized by the Service; or (xii) bypassing the measures we
          may use to prevent or restrict access to the Service, including without limitation features that prevent or
          restrict use or copying of any content or enforce limitations on use of the Service or the content therein; or
          (xiii) monetizing Adinspiration content through advertising, subscriptions or other means.
          We may, without prior notice, change the Service; stop providing the Service or features of the Service, to
          you or to users generally; or create usage limits for the Service. We may permanently or temporarily terminate
          or suspend your access to the Service without notice and liability for any reason, including if in our sole
          determination you violate any provision of this Agreement, or for no reason. Upon termination for any reason
          or no reason, you continue to be bound by this Agreement.
          You are solely responsible for your interactions with other Adinspiration Users. We reserve the right, but
          have no obligation, to monitor disputes between you and other Users. Adinspiration shall have no liability for
          your interactions with other Users, or for any User’s action or inaction.
        </section>
        <section className="tos-text">
          User Content.
          <br/>
          <br/>
          1. Some areas of the Services allow Users to post content, such as profile information, screenshots, comments,
          images, videos, files, job postings, and other content or information. Any such material a User submits,
          posts, displays, or otherwise makes available on the Service is "User Content". User Content includes, without
          limitation, logos, trademarks and service marks, trade names, and other information posted by users, which may
          be used by us for marketing and promotional purposes pursuant to the User Content license grant below. You
          agree to abide by all other applicable terms, such as Adinspiration’s Community Guidelines (which we may
          modify from time to time), when you post User Content and interact with other Users of the Services. You are
          solely responsible for your interactions with other Users. We reserve the right, but have no obligation, to
          monitor disputes between you and other Users. We have no liability for your interactions with other Users, or
          for any User's action or inaction.
        </section>
        <section className="tos-text">
          2. You are entirely responsible for the content of, and any harm resulting from, your User Content. That is
          the case regardless of whether the User Content in question constitutes text, graphics, sound, or other media
          formats. By making User Content available through the Services, you represent and warrant that:
          <br/>
          <ol type="a">
            <li>the downloading, copying and use of the User Content will not infringe the proprietary rights, including
              but not limited to the copyright, patent, trademark or trade secret rights, of any third party;
            </li>
            <li>if your employer has rights to intellectual property you create, you have either (i) received permission
              from your employer to post or make available the User Content, including but not limited to any software,
              or (ii) secured from your employer a waiver as to all rights in or to the User Content;
            </li>
            <li>you have fully complied with any third-party licenses relating to the User Content, and have done all
              things necessary to successfully pass through to end users any required terms;
            </li>
            <li>the User Content does not contain or install any viruses or other harmful or destructive content;</li>
            <li>the User Content is not spam, is not machine- or randomly-generated, and does not contain unethical or
              unwanted commercial content designed to drive traffic to third party sites or boost the search engine
              rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to
              the source of the material (such as spoofing);
            </li>
            <li>the Content is not obscene, libelous or defamatory, hateful or racially or ethnically objectionable, and
              does not violate the privacy or publicity rights of any third party; and
            </li>
            <li>you have, in the case of User Content that includes computer code, accurately categorized and/or
              described the type, nature, uses and effects of the materials, whether requested to do so by Adinspiration
              or otherwise.
            </li>
          </ol>
          By uploading content to Adinspiration, users confirm that their content is original, does not infringe upon
          any third-party intellectual property rights, and complies with all applicable laws and regulations, including
          copyright, trademark, and privacy laws. Users grant Adinspiration a license to use, display, and distribute
          said content in connection with the service, under the condition that such content respects the intellectual
          property rights of others and adheres to established legal standards. Adinspiration reserves the right to
          remove any content that is found to violate these terms or any applicable laws
        </section>
        <section className="tos-text">
          User Content License Grant.

          While you maintain all rights, including copyrights, that you own or control in User Content, you hereby grant
          the following licenses to your User Content:
          <br/>
          <ol type="a">
            <li>To Adinspiration. By posting any User Content on the Services, you expressly grant, and you represent
              and warrant that you have all rights necessary to grant, to Adinspiration a royalty-free, sublicensable,
              transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish,
              list information regarding, edit, translate, distribute, syndicate, publicly perform, publicly display,
              and make derivative works of all such User Content and your name, voice, and/or likeness as contained in
              your User Content, in whole or in part, and in any form, media or technology, whether now known or
              hereafter developed, for use in connection with the Services and Adinspiration’s business, including
              without limitation for promoting and redistributing part or all of the Service (and derivative works
              thereof) in any media formats and through any media channels. Adinspiration may, but is not obliged, to
              cite you as the owner of such User Content in our marketing or other communications.
            </li>
            <li>To Other Users. You also hereby grant each User of the Service a non-exclusive license to access your
              User Content through the Service, as permitted through the functionality of the Services and under these
              Terms.
            </li>
          </ol>
          Additional User Content Prohibitions. You agree not to post User Content that:
          <ol type="a">
            <li>is pornographic, fraudulent, immoral, infringing, illegal, harassing, offensive, or defamatory
              material
            </li>
            <li>may create a risk of harm, loss, physical or mental injury, emotional distress, death, disability,
              disfigurement, or physical or mental illness to you, to any other person, or to any animal;
            </li>
            <li>may create a risk of any other loss or damage to any person or property;</li>
            <li>seeks to harm or exploit children by exposing them to inappropriate content, asking for personally
              identifiable details or otherwise;
              may constitute or contribute to a crime or tort;
            </li>
            <li>contains any information or content that we deem to be unlawful, harmful, abusive, racially or
              ethnically offensive, defamatory, infringing, invasive of personal privacy or publicity rights, harassing,
              humiliating to other people (publicly or otherwise), libelous, threatening, profane, or otherwise
              objectionable;
            </li>
            <li>contains any information or content that is illegal (including, without limitation, the disclosure of
              insider information under securities law or of another party's trade secrets);
            </li>
            <li>contains any information or content that would violate the privacy or publicity rights of any third
              party;
            </li>
            <li>contains any information or content that you do not have a right to make available under any law or
              under contractual or fiduciary relationships;
            </li>
            <li>contains any information or content that you know is not correct and current; or</li>
            <li>promotes racism, bigotry, hatred or physical harm of any kind against any group or individual.</li>
          </ol>
        </section>

        <section className="tos-text">
          2. Our Proprietary Rights
          <br/>
          <br/>
          Our Proprietary Rights. In these Terms, “Intellectual Property Rights” refers to all patent rights, copyright
          rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights,
          goodwill, trade secret rights, and other intellectual property rights as may currently exist or later come
          into being, and all applications, registrations, renewals, and extensions thereof, under the laws of any
          state, province, territory, or other jurisdiction. Except for your User Content, the Services and all
          materials in them or transferred by them, including, but not limited to, software, images, text, graphics,
          illustrations, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos, music, and
          User Content belonging to AdInspiration, other Users, and other licensors to AdInspiration (the "AdInspiration
          Content"), and all Intellectual Property Rights related to it, are the exclusive property of AdInspiration and
          its licensors (including other Users who post User Content to the Services). Except as expressly provided
          herein, nothing in these Terms shall be deemed to create a license in or under any such Intellectual Property
          Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly
          display, publicly perform, publish, adapt, edit, or create derivative works from any AdInspiration Content.
          Use of the AdInspiration Content for any purpose not expressly permitted by these Terms is strictly prohibited

          You may choose to or we may invite you to submit comments or ideas about the Service, including without
          limitation about how to improve the Service or our products (“Ideas”). By submitting any Idea, you agree that
          your disclosure is gratuitous, unsolicited and without restriction and will not place Adinspiration under any
          fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to
          you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge
          that, by acceptance of your submission, Adinspiration does not waive any rights to use similar or related
          ideas previously known to Adinspiration, or developed by its employees, or obtained from sources other than
          you.

        </section>

        <section className="tos-text">
          3. Paid Services
          <br/>
          <br/>
          3.A. Billing Policies. Certain aspects of the Service may be provided for a fee or other charge. Adinspiration
          may add new services for additional fees and charges, or amend fees and charges for existing services, at any
          time in its sole discretion. Any change to our pricing or payment terms shall become effective in the billing
          cycle following notice of such change to you.
          <br/>
          3.B. You agree that all refunds are at the sole discretion of Adinspiration. You may cancel your Adinspiration
          subscription at any time; if you cancel before the end of your billing cycle you have access to your
          subscription for the remainder of the billing cycle. In the event that Adinspiration suspends or terminates
          your subscription or this Agreement for your breach of this Agreement, you understand and agree that you shall
          receive no refund or exchange for any unused time on a subscription, any license or subscription fees for any
          portion of the Service, any content or data associated with your subscription, or for anything else.
          <br/>
          3.C. Payment Information; Taxes. All information that you provide in connection with a purchase or transaction
          or other monetary transaction interaction with the Service must be accurate, complete, and current. You agree
          to pay all charges incurred by users of your credit card, debit card, or other payment method used in
          connection with a purchase or transaction or other monetary transaction interaction with the Service at the
          prices in effect when such charges are incurred. You will pay any applicable taxes, if any, relating to any
          such purchases, transactions or other monetary transaction interactions.
          <br/>
        </section>
        <section className="tos-text">
          4. No Professional Advice
          <br/>
          <br/>
          If the Service provides professional information (for example, financial, legal, economic), such information
          is for informational purposes only and should not be construed as professional advice. No action should be
          taken based upon any information contained in the Service. You should seek independent professional advice
          from a person who is licensed and/or qualified in the applicable area.
        </section>
        <section className="tos-text">
          5. Privacy
          <br/>
          <br/>
          We care about the privacy of our Users. You understand that by using the Services you consent to the
          collection, use and disclosure of your personally identifiable information and aggregate data as set forth in
          our Privacy Policy (https://www.adinspiration.com/privacy), and to have your personally identifiable
          information collected, used, transferred to and processed in the applicable jurisdictions, including Colombia
          and where our clients are based.
        </section>
        <section className="tos-text">
          6. Security
          <br/>
          <br/>
          Adinspiration cares about the integrity and security of your personal information. However, we cannot
          guarantee that unauthorized third parties will never be able to defeat our security measures or use your
          personal information for improper purposes. You acknowledge that you provide your personal information at your
          own risk.
        </section>
        <section className="tos-text">
          7. Third-Party Links
          <br/>
          <br/>
          The Service may contain links to third-party websites, services or other events or activities that are not
          owned or controlled by Adinspiration. Adinspiration does not endorse or assume any responsibility for any such
          third-party sites, information, materials, products, or services. If you access a third party website from the
          Service, you do so at your own risk, and you understand that this Agreement and Adinspiration’s Privacy Policy
          do not apply to your use of such sites. You expressly relieve Adinspiration from any and all liability arising
          from your use of any third-party website, service, or content.
        </section>
        <section className="tos-text">
          8. Indemnity
          <br/>
          <br/>
          You agree to defend, indemnify and hold harmless Adinspiration and its subsidiaries, agents, licensors,
          managers, and other affiliated companies, and their employees, contractors, agents, officers and directors,
          from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney’s fees) arising out of or related to: (i) your use of and access to the
          Service, including any data or content transmitted or received by you; (ii) your violation of any term of this
          Agreement, including without limitation your breach of any of the representations and warranties above; (iii)
          your violation of any third-party right, including without limitation any right of privacy or Intellectual
          Property Rights; (iv) your violation of any applicable law, rule or regulation; (v) any other information or
          content that is submitted via your account including without limitation misleading, false or inaccurate
          information; (vi) negligent or willful misconduct; or (vii) any other party’s access and use of the Service
          with your unique username, password or other appropriate security code.
        </section>
        <section className="tos-text">
          9. No Warranty
          <br/>
          <br/>
          THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM COMPANY OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE
          FOREGOING, COMPANY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS
          ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE
          AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
          CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH
          DOWNLOAD OR YOUR USE OF THE SERVICE.SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
          EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
          RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS AGREEMENT WILL NOT APPLY TO
          THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </section>
        <section className="tos-text">
          10. Limitation of Liability
          <br/>
          <br/>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, in no event shall Adinspiration, its affiliates, agents,
          directors, employees, suppliers, or licensors be liable for any direct, indirect, punitive, incidental,
          special, consequential, or exemplary damages, including without limitation damages for loss of profits,
          goodwill, use, data, or other intangible losses, arising out of or relating to the use of, or inability to
          use, this service. Under no circumstances will Adinspiration be responsible for any damage, loss, or injury
          resulting from hacking, tampering, or other unauthorized access or use of the service or your account or the
          information contained therein.
          To the maximum extent permitted by applicable law, Adinspiration assumes no liability or responsibility for
          any (i) errors, mistakes, or inaccuracies of content; (ii) personal injury or property damage, of any nature
          whatsoever, resulting from your access to or use of our service; (iii) any unauthorized access to or use of
          our secure servers and/or any and all personal information stored therein; (iv) any interruption or cessation
          of transmission to or from the service; (v) any bugs, viruses, trojan horses, or the like that may be
          transmitted to or through our service by any third party; (vi) any errors or omissions in any content or for
          any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise
          made available through the service; and/or (vii) the defamatory, offensive, or illegal conduct of any third
          party. In no event shall Adinspiration, its affiliates, agents, directors, employees, suppliers, or licensors
          be liable to you for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount
          exceeding the amount you paid to Adinspiration hereunder or $100.00, whichever is greater.
          This limitation of liability section applies whether the alleged liability is based on contract, tort,
          negligence, strict liability, or any other basis, even if Adinspiration has been advised of the possibility of
          such damage. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the
          applicable jurisdiction.
          The Service is controlled and operated by Adinspiration from its facilities in Colombia. Adinspiration makes
          no representations that the Service is appropriate or available for use in other locations. Those who access
          or use the Service from other jurisdictions do so at their own volition and are entirely responsible for
          compliance with local laws, including but not limited to export and import regulations.
        </section>
        <section className="tos-text">
          11. Governing Law and Arbitration
          <br/>
          <br/>
          11.A. Governing Law. This Agreement and any action related thereto shall be governed by the laws of Colombia
          without regard to its conflict of laws principles. The parties hereby agree that any disputes arising out of
          or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of Colombia.
          This choice of jurisdiction does not prevent Adinspiration from seeking injunctive relief in any appropriate
          jurisdiction with respect to violation of intellectual property rights.
          <br/>
          <br/>
          11.B. Arbitration. Any dispute, controversy, or claim arising out of, relating to, or in connection with this
          Agreement, including any question regarding its existence, validity, or termination, shall be resolved by
          arbitration administered by the Colombian Chamber of Commerce under its Arbitration Rules. The number of
          arbitrators shall be one. The seat, or legal place, of arbitration shall be Bogota. The language to be used in
          the arbitral proceedings shall be Spanish. The governing law of the contract shall be the substantive law of
          Colombia.
          <br/>
          <br/>
          13.B. Arbitration.
          <br/>
          <br/>
          !
          To modify the arbitration clause to reflect that Adinspiration operates from Colombia without physical
          presence or operations in the applicable jurisdictions, including Colombia and where our clients are based,
          beyond serving clients there and maintaining an LLC, it is necessary to align the arbitration process with
          Colombian legal jurisdiction and possibly international arbitration standards that are recognized in Colombia.
          Below is a revised version of the section:
          <br/>
          <br/>
          13.B. Arbitration.
          <br/>
          <br/>
          READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER
          IN WHICH YOU CAN SEEK RELIEF FROM ADINSPIRATION. In the unlikely event that Adinspiration has not been able to
          resolve a dispute it has with you after 60 days, we each agree to resolve any claim, dispute, or controversy
          (excluding any Adinspiration claims for injunctive or other equitable relief) arising out of or in connection
          with or relating to these Terms of Service, or the breach or alleged breach thereof (collectively, “Claims”),
          by binding arbitration under the rules of the International Chamber of Commerce (ICC) then in effect, except
          as provided herein. The arbitration shall be conducted by a single arbitrator mutually agreed upon by the
          parties, or if no agreement can be reached, then by an arbitrator appointed by the ICC. The place of
          arbitration shall be Bogotá, Colombia, unless both you and Adinspiration agree otherwise. The arbitration
          proceedings shall be conducted in Spanish.
          <br/>
          <br/>
          Each party will be responsible for paying any filing, administrative, and arbitrator fees in accordance with
          ICC rules. The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’
          fees, and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the
          arbitrator may be entered in any court of competent jurisdiction.
          <br/>
          <br/>
          Nothing in this Section shall be deemed as preventing Adinspiration from seeking injunctive or other equitable
          relief from the courts as necessary to protect any of Adinspiration’s proprietary interests.
          ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING.
          THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
          MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS TERMS OF SERVICE, YOU AND COMPANY ARE
          EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, .COLLECTIVE ACTION, PRIVATE
          ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
        </section>
        <section className="tos-text">
          14.A. Assignment. This Agreement, and any rights and licenses granted hereunder, may not be transferred or
          assigned by you without the prior written consent of Adinspiration, which may be withheld at Adinspiration's
          sole discretion. Adinspiration may assign this Agreement, in whole or in part, at any time with or without
          notice to you. Any attempted transfer or assignment in violation of this provision shall be null and void.
          <br/>
          <br/>
          14.B. Notification Procedures and Changes to the Agreement. Adinspiration may provide notifications to you via
          email, written or hard copy notice, or through conspicuous posting of such notice on our website, at our
          discretion, in accordance with local regulations and international best practices. We reserve the right to
          determine the form and means of providing notifications to you, and you may opt out of certain means of
          notification as detailed in this Agreement. Please note that due to the international nature of our
          operations, Adinspiration's approach to notifications and modifications to this Agreement may be adjusted to
          comply with applicable laws and practices in Colombia and in jurisdictions where we operate. Changes to this
          Agreement will be communicated in a timely manner, and your continued use of the Service after such changes
          will constitute your acceptance of the new terms.
          <br/>
          <br/>
          14.C. Entire Agreement/Severability. This Agreement, including any amendments and additional agreements you
          may enter into with Adinspiration regarding the Service, constitutes the entire agreement between you and
          Adinspiration. Should any provision of this Agreement be deemed invalid or unenforceable by a court of
          competent jurisdiction, such invalidity shall not affect the validity of the remaining provisions, which shall
          remain in full force and effect. Notwithstanding, the entire Agreement shall not be deemed unenforceable
          merely due to the invalidity of any single clause, unless otherwise stated by applicable law.
          <br/>
          <br/>
          14.D. No Waiver. No waiver by Adinspiration of any term or condition set forth in this Agreement shall be
          deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition,
          and any failure of Adinspiration to assert a right or provision under this Agreement shall not constitute a
          waiver of such right or provision.
          <br/>
          <br/>
          14.E. International Operations and Government Use. In recognition of Adinspiration's operations from Colombia
          and service to clients globally, including those in the United States, the Service and its documentation are
          provided under the terms that best align with international trade practices and the legal requirements of
          Colombia, unless otherwise required by specific government contracts or agreements. For services rendered to
          the United States government or any agency thereof, the Service is provided as "commercial items," "commercial
          computer software," or "commercial computer software documentation" in accordance with applicable U.S. Federal
          Acquisition Regulation and agency-specific regulations. Use, duplication, or disclosure by the U.S. Government
          is subject to restrictions as set forth in this Agreement and in accordance with current policy governing the
          use of commercial software.
          <br/>
          <br/>
          Note: The inclusion of specific legal terms, references to international law or practices, and adjustments to
          the notification and government use sections should be reviewed and possibly modified by legal counsel to
          ensure compliance with both Colombian laws and the legal requirements of any other jurisdiction in which
          Adinspiration operates or serves clients.
          <br/>
          <br/>
          14.F. Contact. Please contact us at <a href="mailto:hello@adinspiration.com">hello@adinspiration.com</a> with any questions regarding this Agreement.

        </section>
      </article>
    </div>
  );
};

export default TosPage;
