import React from 'react';
import './SaveCollection.scss';
import closeBtn from '../../../assets/images/closeBtn.svg'; // import close
import DefaultBtn from '../../shared/DefaultBtn';
import { useDispatch, useSelector } from 'react-redux';
import { popupMethod } from '../../../redux/myCollection';
import DeleteCollection from '../DeleteCollection/DeleteCollection';
import CollectionItems from "./CollectionItems";

const SaveCollection = () => {
    const dispatch = useDispatch();

    const deleteModal = useSelector((state: any) => state.MyCollectionState.removeCollectionModal);


    return (
        <>
            {deleteModal && <DeleteCollection />}
            <div className='modal saveCollectionModal'>
                <div className='modal-wrapper'>
                    <div
                        className='closeBtn'
                        onClick={() => {
                            dispatch(popupMethod('closeAllModal'));
                        }}
                    >
                        <img src={closeBtn} alt='close-btn' />
                    </div>
                    <div className='modalTitle'>
                        <h2>Save this ad</h2>
                    </div>
                    <div className="collectionItems-Wrapper">
                        <CollectionItems />
                    </div>
                    <div className='buttonWrapper'>
                        <div className='createNew'>
                            <DefaultBtn textBtn='Create new collection' methodBtn='createCollection'/>
                        </div>
                        <div className='done'>
                            <DefaultBtn textBtn='Done' methodBtn='unlimCollection'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SaveCollection;
