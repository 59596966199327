import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import DefaultBtn from "../../components/shared/DefaultBtn";
import './ConfirmPassword.scss';
import { useDispatch } from "react-redux";
import { useResetPasswordMutation, useVerifyValidTokenQuery } from "../../services/adinspiration-api";
import { passwordChangedModal } from "../../redux/rootSlice";

const ConfirmPassword = () => {
  const [searchParams] = useSearchParams();
  const [passwordsMatch, setPasswordsMatch] = useState<Record<string, string>>({
    newPassword: '',
    confirmPassword: '',
  });
  const token = searchParams.get('token');
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [triggerResetPassword, {isError: resetPasswordError}] = useResetPasswordMutation();

  const {isLoading, isError: verificationError} = useVerifyValidTokenQuery({token, id}, {
    skip: !token || !id,
    refetchOnMountOrArgChange: true,
  });

  const handleResetPassword = async () => {
    if (!validateNewPassword()) {
      return;
    }
    try {
      await triggerResetPassword({
        id: id!,
        token: token!,
        password: passwordsMatch.newPassword,
      }).unwrap();
      dispatch(passwordChangedModal('open-password-changed'));
      navigate('/');
      setTimeout(() => {
        dispatch(passwordChangedModal('close-password-changed'));
      }, 3000);
    } catch (err) {
      console.log(err);
      return;
    }
  }

  const validateNewPassword = () => {
    return (passwordsMatch.newPassword === passwordsMatch.confirmPassword) && (passwordsMatch.newPassword !== '' && passwordsMatch.confirmPassword !== '');
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordsMatch({
      ...passwordsMatch,
      [e.target.name]: e.target.value,
    });
  }

  const checkUserAndToken = useCallback(() => {
    if ((!token || !id) || (!isLoading && verificationError)) {
      navigate('/');
      return;
    }
  }, [token, id, isLoading, verificationError, navigate]);

  useEffect(() => {
    checkUserAndToken();
  }, [checkUserAndToken]);


  return (
    <>
      <div className='editProfile'>
        <div className='container'>
          <div className="modalTitle">
            <h2>Reset your current password</h2>
            <div className="enterEmailPass">
              <input
                name="newPassword"
                type="password"
                placeholder="New password"
                onChange={handleChange}
                value={passwordsMatch.newPassword}
              />
            </div>
            <div className="enterEmailPass">
              <input
                name="confirmPassword"
                type="password"
                placeholder="Confirm new password"
                onChange={handleChange}
                value={passwordsMatch.confirmPassword}
              />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              height: '24px',
              color: 'red',
              marginTop: 0,
              fontFamily: 'var(--Inter)',
            }}>
              {resetPasswordError && 'The password cannot be the same as the previous one'}
            </div>
          </div>
          <div onClick={async () => {
            try {
            } catch (err) {
              console.log(err);
              return;
            }
          }}>
            <div onClick={handleResetPassword}>
              <DefaultBtn textBtn='Reset password' methodBtn={'ResetPassword'}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
