import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitPage } from '../../../redux/submitWorkSlice';
import closeBtn from '../../../assets/images/closeBtn.svg';
import backBtn from '../../../assets/images/backBtn.svg';
import DefaultBtn from '../../../components/shared/DefaultBtn';

import '../SubmitWorkPage.scss';
import { useCreateVideoUserMutation } from "../../../services/adinspiration-api";
import { createdAdState } from "../../../redux/rootSlice";

const SubmitWorkPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [videoUrl, setVideoUrl] = useState('');
    //const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [platformType, setPlatformType] = useState<Array<string>>([]);
    const [brandName, setBrandName] = useState('');
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const userState = useSelector((state: any) => state.userState.user);

    const [triggerCreateVideo, {isLoading}] = useCreateVideoUserMutation();

    const goBack = () => {
        dispatch(submitPage('close-submit-page'));
        navigate('/');
        dispatch(createdAdState('created-ad-modal-open'));
        setTimeout(() => {
            dispatch(createdAdState('created-ad-modal-close'));
        }, 5000);
    };

    const handleVideoFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Check if files are selected and not null
        if (e.target.files && e.target.files.length > 0) {
            setVideoFile(e.target.files[0]); // Safely update the state with the selected video file
        } else {
            setVideoFile(null); // Reset or handle the case when no file is selected
        }
    };


    const handlePlatformTypeChange = (e: { target: { options: any; }; }) => {
        const { options } = e.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setPlatformType(value);
    };


    useEffect(() => {
        dispatch(submitPage('open-submit-page'));
    }, [dispatch]);

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // For example, dispatch an action to submit the form data
        try {
            await triggerCreateVideo({
                description,
                videoUrl,
                category,
                platformType,
                brandName,
                video: videoFile,
            }).unwrap();
            goBack();
        } catch (e) {
            console.error(e);
        }
    };

    if (!userState || userState.accountManualVerification === false) {
        dispatch(submitPage('close-submit-page'));
        navigate('/')
    }

    return (
      <div className='submitWorkPage'>
          <form onSubmit={handleSubmit}>
              <div className='container'>
                  <div className='submitPageWrapper'>
                      <div className='backBtn' onClick={() => goBack()}>
                          <img src={backBtn} alt='back-btn' />
                          <span>Back</span>
                      </div>
                      <div className='closeBtn' onClick={() => {}}>
                          <img src={closeBtn} alt='close-btn' />
                      </div>
                      <div className='submitTitle'>
                          <h2>Add new ad creative</h2>
                      </div>
                      <div className="contentWrapper">

                          {/* Video URL */}
                          <div className="brandCategory">
                              <div className="brand">
                                  <div className="contentTitle">
                                      <h5>Video URL</h5>
                                  </div>
                                  <div className="itemWrapper">
                                      <input
                                        type="text"
                                        placeholder="Video URL"
                                        value={videoUrl}
                                        onChange={(e) => setVideoUrl(e.target.value)}
                                      />
                                  </div>
                              </div>
                          </div>

                          {/* Video File Upload */}
                          <div className="brandCategory">
                              <div className="brand">
                                  <div className="contentTitle">
                                      <h5>Video File</h5>
                                  </div>
                                  <div className="itemWrapper">
                                      <input
                                        type="file"
                                        accept="video/*" // This restricts the file input to accept only video files
                                        onChange={handleVideoFileChange}
                                      />
                                  </div>
                              </div>
                          </div>

                          {/* BrandName Field */}
                          <div className="brandCategory">
                              <div className="brand">
                                  <div className="contentTitle">
                                      <h5>Brand Name</h5>
                                  </div>
                                  <div className="itemWrapper">
                                      <input
                                        type="text"
                                        placeholder="Enter ad title"
                                        value={brandName}
                                        onChange={(e) => setBrandName(e.target.value)}
                                      />
                                  </div>
                              </div>
                          </div>

                          {/* Platform Type */}
                          <div>
                              <div className="selectField">
                                  <div className="contentTitle">
                                      <h5>Platform Type</h5>
                                  </div>
                                  <select
                                    multiple={true}
                                    value={platformType}
                                    onChange={handlePlatformTypeChange}
                                  >
                                      <option value="">Select Platform</option>
                                      <option value="tiktok">TikTok</option>
                                      <option value="instagram">Instagram</option>
                                      <option value="facebook">Facebook</option>
                                  </select>
                              </div>
                          </div>

                          {/* Category */}
                          <div className="selectField">
                              <div className="contentTitle">
                                  <h5>Category</h5>
                              </div>
                              <select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                  <option value="">Select Category</option>
                                  <option value="App / Digital">App / Digital</option>
                                  <option value="Apparel / Fashion">Apparel / Fashion</option>
                                  <option value="Beauty / Personal Care">Beauty / Personal Care</option>
                                  <option value="Electronics / Technology">Electronics / Technology</option>
                                  <option value="Food / Beverage">Food / Beverage</option>
                                  <option value="Health / Wellness">Health / Wellness</option>
                                  <option value="Home">Home</option>
                                  <option value="Kids / Babies">Kids / Babies</option>
                                  <option value="Local Services / Businesses">Local Services / Businesses</option>
                                  <option value="Pets">Pets</option>
                                  <option value="Other">Other</option>
                                  {/* Add other categories as needed */}
                              </select>
                          </div>

                          {/* Description */}
                          <div className="descriptionContent">
                              <div className="contentTitle">
                                  <h5>Ad Description</h5>
                              </div>
                              <div className="itemWrapper">
                                    <textarea
                                      name="description"
                                      id="1"
                                      placeholder="Add a short description about the work behind this ad"
                                      value={description}
                                      onChange={(e) => setDescription(e.target.value)}
                                    />
                              </div>
                          </div>

                          {/* Add other fields as necessary */}

                          <div className="buttonWrapper">
                              <div className="cancelBtn" onClick={goBack}>
                                  <DefaultBtn textBtn="Cancel" methodBtn="cancel-submit" disabled={isLoading}/>
                              </div>
                              <div className="adNew">
                                  <DefaultBtn textBtn="Add new ad" methodBtn="add-new-ad" disabled={isLoading}/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </form>
      </div>
    );
};

export default SubmitWorkPage;
