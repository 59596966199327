import React from 'react';
import './Shared.scss';

const ChoiceMethod = () => {
    return (
        <div className='choiceMethod'>
            <hr />
            <span> or </span>
        </div>
    );
};

export default ChoiceMethod;
