import { useRef, useState, useCallback, useMemo, memo, FC } from 'react';
import './Thumb.scss';
import './MediaThumb.scss';
import { copiedLinkState, creatorBrand, popup, setCurrentVideo } from '../../redux/rootSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MuxPlayer from "@mux/mux-player-react/lazy";

import { companyType } from "../../model/user";
import  { addAdToCollection, popupMethod } from "../../redux/myCollection";
import { useDeleteAdMutation, useDeleteVideoFromCollectionMutation } from "../../services/adinspiration-api";
import { MuxPlayerRefAttributes } from "@mux/mux-player-react";

interface ThumbModel {
    avatar?: string;
    userName?: string;
    playbackId?: string;
    videoId?: string;
    viewerId?: string;
    videoTitle?: string;
    isFavorite?: boolean;
    userId?: string;
    originalLink?: string;
    companyTypeP?: companyType;
    blurred?: boolean;
    handleDelete?: any,
    collectionId?: string,
    videoCollectionOwner?: string,
}

const Thumb: FC<ThumbModel> = memo(({ avatar, userName, playbackId, videoId, viewerId, videoTitle, companyTypeP, isFavorite, userId, originalLink, blurred, collectionId, videoCollectionOwner }) => {
    const [playClass, setPlayClass] = useState<string>('playBtn');
    const playerRef = useRef<MuxPlayerRefAttributes>(null);
    const [favorited, setFavorited] = useState<boolean | undefined>(isFavorite);
    const isFavoriteClass = useMemo(() => favorited ? 'notesBtn-favorited' : 'notesBtn', [favorited]);
    const userIsGuest = useSelector((state: any) => state.logInState.guestStatus);
    const adminStatus = useSelector((state: any) => state.adminState.auth);
    const currentUser = useSelector((state: any) => state.userState.user);
    const currentVideo = useSelector((state: any) => state.mainState.currentVideo);
    const [triggerDeleteAd] = useDeleteAdMutation();
    const [triggerDeleteVideoFromCollection] = useDeleteVideoFromCollectionMutation();

    const dispatch = useDispatch();

    const togglePlay = useCallback(async () => {
        if (blurred) {
            return;
        }


        if (currentVideo !== playbackId && currentVideo !== '') {
            return;
        }

        if (playClass === 'playBtn pauseHover' || playClass === 'playBtn') {
            setPlayClass('pauseBtn');
            dispatch(setCurrentVideo(playbackId))
            await playerRef.current?.play();
        } else {
            setPlayClass('playBtn pauseHover');
            dispatch(setCurrentVideo(''));
            playerRef.current?.pause();
        }
    }, [blurred, playClass, playbackId, currentVideo, dispatch]);



    const handleLinkCopied = useCallback(async () => {
        if (blurred) {
            return;
        }
        dispatch(copiedLinkState('copied-modal-open'));
        const urlToShare = `${window.location.origin}/ad/${videoId}`;
        await navigator.clipboard.writeText(urlToShare);

        setTimeout(() => {
            dispatch(copiedLinkState('copied-modal-close'));
        }, 5000);
    }, [blurred, dispatch, videoId]);

    async function handleDeleteAd(): Promise<void> {
        try {
            await triggerDeleteAd({ videoId }).unwrap();
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    }

    async function handleDeleteAdFromCollection(): Promise<any> {
        try {
            await triggerDeleteVideoFromCollection({ collectionId, videoId  }).unwrap();
            window.location.reload();
        } catch (err) {
            console.error(err);
        }
    }

    const handleFavorited = useCallback(async () => {
        if (blurred) {
            return;
        }
        if (userIsGuest) {
            dispatch(popup('open-signIn'));
            return;
        }
        dispatch(popupMethod('saveCollection'));
        dispatch(addAdToCollection({ id: videoId }));
    }, [blurred, userIsGuest, dispatch, videoId]);

    return (
        <div className='thumbItemWrapper'>
            <div
              className="thumbItem"
              onClick={() => {
                  if (blurred) {
                      return;
                  }
                  dispatch(creatorBrand('creator'));
              }}
            >
                <div className="thumbImg">
                    <MuxPlayer
                      ref={playerRef}
                      className={blurred ? 'mux-player thumbitemBlurred' : 'mux-player'}
                      streamType="on-demand"
                      playbackId={playbackId}
                      style={{
                          aspectRatio: "21/1",
                      }}
                      preload="metadata"
                      placeholder={`https://image.mux.com/${playbackId}/thumbnail.png?width=214&height=500`}
                      metadata={{
                          video_id: videoId,
                          video_title: videoTitle,
                          viewer_user_id: viewerId,
                      }}
                    />
                </div>
                <div className="thumbHover">
                    {/* TODO make function for copyBtn to show popup "Link copied" */}
                    <button className="copyBtn" onClick={handleLinkCopied}></button>
                    {/* TODO make function for notesBtn to show popup with login */}
                    <button className={isFavoriteClass} onClick={handleFavorited}></button>
                    {/* TODO make function for edit button */}
                    {(adminStatus || (!userIsGuest && currentUser?.id) === videoCollectionOwner) && <button className="deleteBtn" onClick={collectionId ? handleDeleteAdFromCollection : handleDeleteAd}></button>}
                    {/* <button className='editBtn'></button> */}

                </div>
                <button className={playClass} onClick={() => togglePlay()}></button>

            </div>

            <Link to={companyTypeP === companyType.Brand ? `/brand-page/${userId}` : `/creator-page/ads/${userId}`}>
                <div
                    className='userBlock'
                    onClick={() => {
                        if (blurred) {
                            return;
                        }
                        dispatch(creatorBrand('brand'));
                    }}
                >
                    <div className='userAvatar'>
                        <img src={avatar} alt='' style={{
                            borderRadius: '50%',
                            width: '30px',
                            height: '30px',
                        }}/>
                    </div>
                    <div className='userName'>{userName}</div>
                </div>
            </Link>
        </div>
    );
});

export default Thumb;
