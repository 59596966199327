import React from 'react';
import LogFooter from './LogFooter/LogFooter';
import UnlogFooter from './UnlogFooter/UnlogFooter';
import { useSelector } from 'react-redux';

const Footer = () => {
    const user = useSelector((state: any) => state.userState.user);


    return (
        <>
            {user && <LogFooter />}
            {!user && <UnlogFooter />}
        </>
    );
};

export default Footer;
