import React, { useEffect, useRef, useState } from "react";
import './ThumbWrapper.scss'
import './MediaThumbWrapper.scss'
import { useSelector } from "react-redux";
import userProfileImg from "../../assets/images/user-avatar.png";
import { Category, useGetAdsFromUserQuery } from "../../services/adinspiration-api";
import { useLocation, useParams } from "react-router-dom";
import ThumbAds from "../Thumb/ThumbAds";
const ThumbWrapper = () => {
    const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
    const urlParam = useParams();
    const location = useLocation();
    const prevCategoryRef = useRef<string>('');

    // Use URLSearchParams to parse the query parameters
    const searchParams = new URLSearchParams(location.search);
    const category = searchParams.get('category');

    const validateCategory = (query: string | null): Category | undefined => {
        return Object.values(Category).find((value) => value === query) ?? undefined;
    };

    const [page, setPage] = useState<number>(1);
    const { data, error, isLoading, isFetching } = useGetAdsFromUserQuery({
        page: page,
        limit: 8,
        category: validateCategory(category),
        userId: urlParam?.id
    },{
        refetchOnMountOrArgChange: true,
    });


    useEffect(() => {
        // Can be better... TODO: refactor
        const onScroll = () => {
            const scrolledToBottom =
              ((window.innerHeight * .85) + (window.scrollY * .90)) >= document.body.offsetHeight * ((page * 1.03));
            // @ts-ignore
            if (scrolledToBottom && !isFetching && data.videos?.length < data.total) {
                setPage(page + 1);
            }
        };

        document.addEventListener("scroll", onScroll);

        return function () {
            document.removeEventListener("scroll", onScroll);
        };
    }, [page, isFetching,data?.total, data?.videos?.length]);


    useEffect(() => {
        // Check if category has changed
        if (prevCategoryRef.current !== category) {
            setPage(1); // Reset to the first page
            prevCategoryRef.current = category || '';
        }
    }, [category]);

    if (error) {
        return <div>Something went wrong</div>
    } else if (data?.total === 0 && !isLoading) {
        return <div>There are no videos here...</div>
    }


    return (
      <div>
          <div style={{
              height: '100%',
              width: '100%',
              zIndex: -50,
          }}>

          </div>
          {isLoading ? null :
            <div className="thumbWrapper">
                {data?.videos?.map((item) => {
                    if (guestStatus) {
                        return (
                          <div key={item._id}>
                              {item.playbackIds.map((playbackId) => (
                                <ThumbAds
                                  key={playbackId.id}
                                  avatar={item.user.avatar || userProfileImg}
                                  userName={item.user.firstName}
                                  videoId={item._id}
                                  playbackId={playbackId.id}
                                  viewerId={'Guest'}
                                  videoTitle={item.title}
                                  isFavorite={item.isFavorite}
                                />
                              ))}
                          </div>
                        );
                    } else if (!guestStatus) {
                        return (
                          <div key={item._id}>
                              {item.playbackIds.map((playbackId) => (
                                <ThumbAds
                                  key={playbackId.id}
                                  avatar={item.user.avatar || userProfileImg}
                                  userName={item.user.firstName}
                                  videoId={item._id}
                                  playbackId={playbackId.id}
                                  viewerId={item.user._id}
                                  videoTitle={item.title}
                                  isFavorite={item.isFavorite}
                                />
                              ))}
                          </div>
                        );
                    }
                    return false;
                })}

            </div>
          }
      </div>
    );
}

export default ThumbWrapper;
