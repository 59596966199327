import React from 'react';
// import { useDispatch } from "react-redux";
import ContGoogle from '../../shared/ContGoogle';
import ChoiceMethod from '../../shared/ChoiceMethod';
import EnterEmail from '../../shared/EnterEmail';
import DefaultBtn from '../../shared/DefaultBtn';
import './UnlogFooter.scss';
import { popup } from "../../../redux/rootSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLazyCheckEmailQuery } from "../../../services/adinspiration-api";

const UnloggedFooter = () => {
    const dispatch = useDispatch();
    const [triggerCheck] = useLazyCheckEmailQuery();
    const emailError = useSelector((state: any) => state.mainState.emailError);

    const emailValue = useSelector((state: any) => state.mainState.enterEmail);

    const checkEmail = async () => {
        try {
            await triggerCheck(emailValue).unwrap();
            dispatch(popup('confirm-signIn'));
        } catch (err) {
            dispatch(popup('email-error'));
        }
    }

    return (
        <>
            <div className='unlog-footer'>
                <div className='content-wrapper'>
                    <div className='unlog-title'>
                        <span>
                            Sign up to discover 1000+ video ads and latest trends
                        </span>
                    </div>
                    <ContGoogle />
                    <ChoiceMethod />
                    <div className='email-title'>
                        <span>Email</span>
                    </div>
                    <EnterEmail />
                    {
                        emailError ? <div className='errorText'>This email is invalid or is already taken</div> : null
                    }
                    <div  style={{
                        width: '100%',
                    }} onClick={checkEmail}>
                        <DefaultBtn textBtn='Continue with email' methodBtn={'Login'} />
                    </div>
                    <div className='unlog-description'>
                        <span>
                            By creating an account you agree with our <a href='/terms'>Terms of Service</a> and <a href='/privacy'>Privacy Policy.</a>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UnloggedFooter;
