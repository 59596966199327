import React, { useState } from 'react';
import DefaultBtn from "../../../../components/shared/DefaultBtn";
import downloadBtn from "../../../../assets/images/downloadBtn.svg";
import defaultUser from '../../../../assets/images/defaultUser.png';
import './CreateUser.scss';
import downBtn from "../../../../assets/images/downBtn.svg";
import { useCreateUserMutation, useGetRolesQuery } from "../../../../services/adinspiration-api";
import { RoleType } from "../../../../model/creatorModel";
import { companyType, IRole } from "../../../../model/user";
import LinkCopied from "../../../../components/Modals/LinkCopied/LinkCopied";

const CreateUser = () => {
  const [chooseTypeModal, setSchooseTypeModal] = useState<boolean>(false);
  const [inputPlaceholder, setInputPlaceholder] = useState<companyType>(companyType.Agency);
  const [chooseRoleModal, setChooseRoleModal] = useState<boolean>(false);
  const [inputRolePlaceholder, setInputRolePlaceholder] = useState<string>('Choose role');
  const [refAvatar, setRefAvatar] = useState<File>();
  const [avatar, setAvatar] = useState<string>('');
  const fileInputRef: React.LegacyRef<HTMLInputElement> = React.createRef();
  const [role, setRole] = useState<IRole>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [createdUser, setCreatedUser] = useState<boolean>(false);

  const { data } = useGetRolesQuery();
  const [triggerCreateUser, {isLoading}] = useCreateUserMutation();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // get the file
    if (file) {
      setRefAvatar(file);
      const url = URL.createObjectURL(file);
      setAvatar(url);
    }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setFirstName('');
    setLastName('');
    setCategory('');
    setAvatar('');
    setRefAvatar(undefined);
  }

  const handleCreateUser = async () => {
    const user = {
      email,
      password,
      firstName,
      lastName,
      category,
      companyType: inputPlaceholder,
      avatar: refAvatar,
      role,
    };
    try {
      await triggerCreateUser(user).unwrap();
      resetForm();
      setCreatedUser(true);
      setTimeout(() => {
        setCreatedUser(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="userWrapper" style={{

    }}>
      <div className='userManagementInfo'>
        <div className='userManagementInfo-wrapper'>
          <div className='userAvatar'>
            <img src={avatar ? avatar : defaultUser} alt='user-avatar'/>
            <div className="avatarBtn" onClick={() => fileInputRef?.current?.click()}>
              <img src={downloadBtn} alt="download-btn"/>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleImageUpload}
              />
              <span>Replace photo</span>
            </div>
          </div>
          <div className="inputItem info1">
            <div className="inputWrapper">
              <h4>First name</h4>
              <input type="text" placeholder="Bohdan" required={true} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
            </div>

            <div className="inputWrapper">
              <h4>Last name</h4>
              <input type="text" placeholder="Mukha" required={true} value={lastName} onChange={(e) => setLastName(e.target.value)}/>
            </div>
          </div>
          <div className="passwordWrapper">
            <div style={{
              display: 'flex',
              width: '100%',
            }}>
              <div className="inputWrapper email">
                <h4>Email</h4>
                <input type="email" placeholder="user.email@gmail.com" required={true} value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
            </div>
            <div style={{
              display: 'flex',
              width: '100%',
            }}>
              <div className="inputWrapper pass">
                <h4>Password</h4>
                <input type="password" placeholder="Password1234" required={true} value={password} onChange={(e) => setPassword(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className="companyWrapper company-types">
            <div className="companyTitle">
              <h5>Company type</h5>
            </div>
            <div
              className="companyInput"
              onClick={(event) => {
                event.stopPropagation();
                setSchooseTypeModal(!chooseTypeModal);
              }}
            >
              <div
                // type="select"
                className="companyTypeInput"
                // placeholder={inputPlaceholder}
              >
                {inputPlaceholder}
              </div>
              <img src={downBtn} alt="down-btn"/>
            </div>

            {chooseTypeModal && (
              <div className="companyType">
                <div>
                  <ul>
                    <li
                      onClick={() => {
                        setInputPlaceholder(companyType.Agency);
                        setSchooseTypeModal(!chooseTypeModal);
                      }}
                    >
                      <span>Agency</span>
                    </li>
                    <li
                      onClick={() => {
                        setInputPlaceholder(companyType.Brand);
                        setSchooseTypeModal(!chooseTypeModal);
                      }}
                    >
                      <span>Brand</span>
                    </li>
                    <li
                      onClick={() => {
                        setInputPlaceholder(companyType.Software);
                        setSchooseTypeModal(!chooseTypeModal);
                      }}
                    >
                      <span>Software Company</span>
                    </li>
                    <li
                      onClick={() => {
                        setInputPlaceholder(companyType.UGC);
                        setSchooseTypeModal(!chooseTypeModal);
                      }}
                    >
                      <span>UGC Creator</span>
                    </li>
                    <li
                      onClick={() => {
                        setInputPlaceholder(companyType.Other);
                        setSchooseTypeModal(!chooseTypeModal);
                      }}
                    >
                      <span>Other</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

          </div>
          <div className="companyWrapper company-types">
            <div className="companyTitle">
              <h5>Role</h5>
            </div>
            <div
              className="companyInput"
              onClick={(event) => {
                event.stopPropagation();
                setChooseRoleModal(!chooseRoleModal);
              }}
            >
              <div
                // type="select"
                className="companyTypeInput"
                // placeholder={inputPlaceholder}
              >
                {inputRolePlaceholder}
              </div>
              <img src={downBtn} alt="down-btn"/>
            </div>

            {chooseRoleModal && (
              <div className="companyType">
                <div className="companyTypeList">
                  <ul>
                    {data?.roles.map((role: any) => (
                      <li
                        onClick={() => {
                          setInputRolePlaceholder(RoleType[role.type]);
                          setChooseRoleModal(!chooseRoleModal);
                          setRole({
                            _id: role._id,
                            type: role.type,
                            description: role.description,
                          });
                        }}
                      >
                        <span>{RoleType[role.type]}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

          </div>
          <div className="button-block buttons">
            <DefaultBtn textBtn="Cancel" methodBtn="change-btn" />
            <div onClick={handleCreateUser}>
              <DefaultBtn textBtn="Save" methodBtn="change-btn" disabled={isLoading} />
            </div>
          </div>
        </div>
      </div>
      {createdUser && <LinkCopied textToDisplay="User created"/>}
    </div>
  );
};

export default CreateUser;
