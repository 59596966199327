import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { popup } from '../../../redux/rootSlice';
import closeBtn from '../../../assets/images/closeBtn.svg';
import ContGoogle from '../../shared/ContGoogle';
import ChoiceMethod from '../../shared/ChoiceMethod';
import EnterEmail from '../../shared/EnterEmail';
import EnterPass from '../../shared/EnterPass';
import DefaultBtn from '../../shared/DefaultBtn';

import '../AllModalsStyle.scss';
import './LogIn.scss';
import { useLoginMutation } from "../../../services/adinspiration-api";
import { forgotPassword } from "../../../redux/forgotPasswordSlice";
import { usePostHog } from "posthog-js/react";

const LogIn = () => {
    const dispatch = useDispatch();
    const [login] = useLoginMutation();
    const enterEmail = useSelector((state: any) => state.mainState.enterEmail);
    const enterPassword = useSelector((state: any) => state.mainState.enterPassword);
    const posthog = usePostHog();

    const handleSubmit = async () => {
        try {
            const data = await login({ email: enterEmail, password: enterPassword }).unwrap();
            posthog.identify(enterEmail, {
                id: data.user.id,
                email: enterEmail,
                firstName: data.user.firstName,
                lastName: data.user?.lastName ?? '',
                role: data.user.role.description,
                companyType: data.user.companyType
            })
            dispatch(popup('confirm-logIn'));

        } catch (err) {
            dispatch(popup('email-error'));
            dispatch(popup('password-error'));
        }
    }

    return (
        <>
            <div className='modal login'>
                <div className='modal-wrapper'>
                    <div className='closeBtn' onClick={() => dispatch(popup('close-logIn'))}>
                        <img src={closeBtn} alt='close-btn' />
                    </div>
                    <div className='modalTitle'>
                        <h2>Welcome back!</h2>
                    </div>
                    <ContGoogle />
                    <ChoiceMethod />
                    <EnterEmail />
                    <EnterPass />
                    <p className="forgotPassword" onClick={() => {
                        dispatch(popup('close-logIn'));
                        dispatch(forgotPassword('open-forgot-password'));
                    }}>
                        Forgot your password?
                    </p>
                    <div onClick={handleSubmit}>
                        <DefaultBtn textBtn='Sign in' methodBtn={'Login'} />
                    </div>
                    <div className='modalDescription'>
                        <span>Don’t you have an account?</span>

                        <span className='descriptionLink' onClick={() => dispatch(popup('goToSignInModal'))}>
                            Sign Up
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogIn;
