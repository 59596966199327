import React, { useEffect, useState } from 'react';
import './ThumbWrapper.scss';
import './MediaThumbWrapper.scss';
import ThumbCollection from '../Thumb/ThumbCollection';
import { useGetCollectionsFromUserQuery } from "../../services/adinspiration-api";
import { useParams } from "react-router-dom";

const ThumbCollectionWrapper = () => {
    const urlParam = useParams();
    const [page, setPage] = useState<number>(1);
    const {data, isFetching, isLoading, error} = useGetCollectionsFromUserQuery({
        page: page,
        limit: 8,
        userId: urlParam?.id
    },{
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        // Can be better... TODO: refactor
        if (error) {
            return;
        }
        const onScroll = () => {
            const scrolledToBottom =
              ((window.innerHeight * .85) + (window.scrollY * .92)) >= document.body.offsetHeight * ((page * 1.01));
            if (!data) {
                return;
            }
            if (scrolledToBottom && !isFetching && data.data?.length < data.total) {
                setPage(page + 1);
            }
        };

        document.addEventListener("scroll", onScroll);

        return function () {
            document.removeEventListener("scroll", onScroll);
        };
    }, [page, isFetching, data?.total, data?.data?.length, data, error]);

    if (isLoading) {
        return <div>Loading...</div>
    } else if (error) {
        return <div>Something went wrong</div>
    }

    if (data?.data.length === 0 && !isLoading) {
        return <div>There are no collections here...</div>
    }

    return (
        <div className='thumbWrapper'>
            {data?.data.map((item) => {
                return <ThumbCollection collectionData={item} key={item._id} />;
            })}

        </div>
    );
};

export default ThumbCollectionWrapper;
