import React from 'react';
import './SelectPlanModal.scss';
import DefaultBtn from '../../shared/DefaultBtn';
import { useDispatch, useSelector } from "react-redux";
import { setInfoSliceError } from "../../../redux/getInfoSlice";
import { useCheckoutMutation, useSignUpMutation, useUpdateUserMutation } from "../../../services/adinspiration-api";
import { type companyType } from "../../../model/user";
import { usePostHog } from "posthog-js/react";

const Plan = (props: { freeTextBtn: string; freeMethodBtn: string; proTextBtn: string; proMethodBtn: string; disabled?: boolean }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.userState.user);
    const answer = useSelector((state: any) => state.getInfoState.answer);
    const firstName = useSelector((state: any) => state.welcomeModalState.firstName);
    const lastName = useSelector((state: any) => state.welcomeModalState.lastName);
    const email = useSelector((state: any) => state.mainState.enterEmail);
    const password = useSelector((state: any) => state.mainState.createPassword);
    const companyType: companyType = useSelector((state: any) => state.welcomeModalState.inputPlaceholder);
    const discoveryChannel = useSelector((state: any) => state.getInfoState.answerMessage);
    const posthog = usePostHog();

    const [signUpMutation] = useSignUpMutation();
    const [triggerUpdateUser] = useUpdateUserMutation();
    const [triggerCheckout] = useCheckoutMutation();

    const handleValidation = () => {
        if (!discoveryChannel) {
            dispatch(setInfoSliceError('message-error'));
            return false
        }
        if (!answer) {
            dispatch(setInfoSliceError('answer-error'));
            return false
        }
        return true
    }

    const isUserLoggedIn = () => !!user;


    const handleSignUp = async () => {
        if (!handleValidation()) return;
        try {
            let intent;

            switch (answer) {
                case 'answer1':
                    intent = 'Discovering the latest trends and winning ad ideas';
                    break;
                case 'answer2':
                    intent = 'Finding UGC creators to hire';
                    break;
                case 'answer3':
                    intent = 'Sharing my own ad creatives';
                    break;
                default:
                    intent = 'Discovering the latest trends and winning ad ideas';
                    break;
            }

            if (isUserLoggedIn()) {
                await triggerUpdateUser({
                    firstName,
                    lastName,
                    companyType,
                    intentToHire: intent,
                    discoveryChannel,
                    email: user.email
                });
            } else {
                await signUpMutation({
                    firstName,
                    lastName,
                    email,
                    password,
                    companyType,
                    intentToHire: intent,
                    discoveryChannel
                });
                posthog.identify(email, {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    companyType: companyType,
                    intentToHire: intent,
                    discoveryChannel: discoveryChannel
                });
                posthog.capture('Sign up - Free User', {
                    email: email,
                    companyType: companyType,
                    intentToHire: intent,
                    discoveryChannel: discoveryChannel
                });
            }
            // Reload page
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    }

    const handleSignUpPro = async () => {
        if (!handleValidation()) return;
        try {
            let intent;

            switch (answer) {
                case 'answer1':
                    intent = 'Discovering the latest trends and winning ad ideas';
                    break;
                case 'answer2':
                    intent = 'Finding UGC creators to hire';
                    break;
                case 'answer3':
                    intent = 'Sharing my own ad creatives';
                    break;
                default:
                    intent = 'Discovering the latest trends and winning ad ideas';
                    break;
            }

            if (isUserLoggedIn()) {
                await triggerUpdateUser({
                    firstName,
                    lastName,
                    companyType,
                    intentToHire: intent,
                    discoveryChannel,
                    email: user.email
                });
            } else {
                await signUpMutation({
                    firstName,
                    lastName,
                    email,
                    password,
                    companyType,
                    intentToHire: intent,
                    discoveryChannel
                });
                posthog.identify(email, {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    companyType: companyType,
                    intentToHire: intent,
                    discoveryChannel: discoveryChannel
                });
                posthog.capture('Sign up - Pro User', {
                    email: email,
                    companyType: companyType,
                    intentToHire: intent,
                    discoveryChannel: discoveryChannel
                });
            }
            // Reload page
            const {url} = await triggerCheckout().unwrap();
            window.location.href = `${url}`
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='planWrapper'>
                <div className={`plan free`}>
                    <div className='planType'>
                        <div className='type'>
                            <h2>Free</h2>
                        </div>
                    </div>
                    <div className='list'>
                        <ul>
                            <li>
                                <span>Browse latest 100 ads</span>
                            </li>
                            <li>
                                <span>Limited search</span>
                            </li>
                            <li>
                                <span>Limited Collections</span>
                            </li>
                        </ul>
                    </div>
                    <div className="planBtn">
                            <div onClick={handleSignUp}>
                            <DefaultBtn textBtn={props.freeTextBtn} methodBtn={props.freeMethodBtn}/>
                        </div>
                    </div>
                </div>
                <div className={`plan pro activePlan`}>
                    <div className='planType'>
                        <div className='type'>
                            <h2>Pro</h2>
                        </div>
                        <div className='coast'>
                            $8/mo
                            <span>Billed annually</span>
                        </div>
                    </div>
                    <div className='list'>
                        <ul>
                            <li>
                                <span>Unlimited access to ads</span>
                            </li>
                            <li>
                                <span>Unlimited search</span>
                            </li>
                            <li>
                                <span>Save favourites</span>
                            </li>
                            <li>
                                <span>Unlimited collections</span>
                            </li>
                            <li>
                                <span>Shared collections</span>
                            </li>
                        </ul>
                    </div>
                    <div className="planBtn" onClick={handleSignUpPro}>
                        <DefaultBtn textBtn={props.proTextBtn} methodBtn={props.proMethodBtn}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Plan;
