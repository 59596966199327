import React, { useEffect, useState } from "react";
import './ThumbWrapper.scss'
import './MediaThumbWrapper.scss'
import Thumb from "../Thumb/Thumb";
import { useSelector } from "react-redux";
import userProfileImg from "../../assets/images/user-avatar.png";
import { useGetAdsSearchQuery } from "../../services/adinspiration-api";
import BlurredVideos from "../Modals/BlurredVideos/BlurredVideos";
import PopupBlurredVideos from "../Modals/PopupBlurredVideos/PopupBlurredVideos";

interface ThumbAdsSearchWrapperProps {
  setNumberAds: (number: number) => void;
  setNumberOfBlurredAds: (number: number) => void;
}

const ThumbAdsSearchWrapper = ({setNumberAds, setNumberOfBlurredAds}: ThumbAdsSearchWrapperProps) => {
  const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
  const queryParams = new URLSearchParams(window.location.search);
  const userFree = useSelector((state: any) => state.logInState.userFree);


  const [page, setPage] = useState<number>(1);
  const { data, error, isLoading, isFetching } = useGetAdsSearchQuery({
    page: page,
    limit: 8,
    search: queryParams.get('search') ?? "Mux"
  });


  useEffect(() => {
    // Can be better... TODO: refactor
    if (error) {
      return;
    }
    const onScroll = () => {
      if (data?.videos?.length === data?.total) {
        return;
      }
      const scrolledToBottom =
        ((window.innerHeight * .85) + (window.scrollY * .92)) >= document.body.offsetHeight * ((page * .50));
      // @ts-ignore
      if (scrolledToBottom && !isFetching && data.videos?.length < data.total) {
        setPage(page + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, data?.total, data?.videos?.length, error]);

  if (error) {
    return <div>Something went wrong</div>
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      {isLoading ? null :
        <div className="thumbWrapper">
          {data?.videos?.map((item) => {
            setNumberAds(data.total);
            if (data?.realCount && data?.realCount > 0) {
              setNumberOfBlurredAds(data.realCount);
            }
            if (guestStatus) {
              return (
                <div key={item._id}>
                  {item.playbackIds?.map((playbackId) => (
                    <Thumb
                      key={playbackId.id}
                      avatar={item?.user?.avatar || userProfileImg}
                      userName={item?.user?.firstName}
                      videoId={item._id}
                      playbackId={playbackId.id}
                      viewerId={'Guest'}
                      videoTitle={item.title}
                      //isFavorite={item.isFavorite}
                      userId={item?.user?._id}
                      companyTypeP={item?.user?.companyType}
                      originalLink={item.originalLink}
                    />
                  ))}
                </div>
              );
            } else if (!guestStatus) {
              return (
                <div key={item._id}>
                  {item.playbackIds?.map((playbackId) => (
                    <Thumb
                      key={playbackId.id}
                      avatar={item?.user?.avatar || userProfileImg}
                      userName={item?.user?.firstName}
                      videoId={item._id}
                      playbackId={playbackId.id}
                      viewerId={item?.user?._id}
                      videoTitle={item.title}
                      //isFavorite={item.isFavorite}
                      userId={item?.user?._id}
                      companyTypeP={item?.user?.companyType}
                      originalLink={item.originalLink}
                    />
                  ))}
                </div>
              );
            }
            return false;
          })}
        </div>
      }
      {userFree &&
        <PopupBlurredVideos>
        <div className="thumbWrapper">
          <BlurredVideos/>
        </div>
      </PopupBlurredVideos>
      }
    </div>
  );
}

export default ThumbAdsSearchWrapper;
