import Header from './components/Header/Header';
import MainPage from './pages/MainPage/MainPage';
import './style/App.scss';
import './style/MediaApp.scss';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SignIn from './components/Modals/SignIn/SignIn';
import CreatePassword from './components/Modals/CreatePassword/CreatePassword';
import WelcomeModal from './components/Modals/WelcomeModal/WelcomeModal';
import GetInfo from './components/Modals/GetInfoModal/GetInfoModal';
import SelectPlan from './components/Modals/SelectPlanModal/SelectPlanModal';
import CreatorPage from './pages/CreatorPage/CreatorPage';
import BrandPage from './pages/BrandPage/BrandPage';
import CollectionPage from './pages/CollectionName/CollectionName';
import SearchPage from './pages/SearchQuery/SearchQuery';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import AdminPanel from './admin/AdminPanel/AdminPanel';
import AdminLogin from './admin/AdminPanel/AdminLogin';
import EditProfile from './pages/EditProfile/EditProfile';
import LinkCopied from './components/Modals/LinkCopied/LinkCopied';

import SubmitWorkPage from './pages/SubmitWorkPage/SubmitWorkPage/SubmitWorkPage';
import EditWorkPage from './pages/SubmitWorkPage/EditWorkPage/EditWorkPage';
import MySubscription from './pages/MySubscription/MySubscription';
import BillingPage from './pages/PaymentPage/BillingPage';

import VideoModeration from './admin/AdminPanel/adminPages/VideoModeration/VideoModeration';
import UserManagement from './admin/AdminPanel/adminPages/UserManagement/UserManagement';
import AnalyticsPage from './admin/AdminPanel/adminPages/AnalyticsPage/AnalyticsPage';
import ThumbCollectionWrapper from './components/ThumbWrapper/ThumbCollectionWrapper';
import ThumbAdsWrapper from './components/ThumbWrapper/ThumbAdsWrapper';
import CreateCollection from "./components/Modals/CreateCollection/CreateCollection";
import SaveCollection from "./components/Modals/SaveCollection/SaveCollection";
import UnlimCollections from "./components/Modals/SelectPlanModal/UnlimCollections";
import AdPage from "./pages/Ad/AdPage";
import { useGetSessionQuery } from "./services/adinspiration-api";
import CollectionVideosPage from "./pages/CollectionsVIdeos/CollectionVideosPage";
import PrivacyPage from "./pages/Privacy/PrivacyPage";
import TosPage from "./pages/Tos/TosPage";
import SubmitWorkPageAdmin from "./admin/AdminPanel/adminPages/AdminSubmitAds/SubmitWorkPageAdmin";
import CreateUser from "./admin/AdminPanel/adminPages/CreateUserPage/CreateUser";
import MigrationPage from "./admin/AdminPanel/adminPages/MigrationPage/MigrationPage";
import MigrationBrandPage from "./admin/AdminPanel/adminPages/MigrationBrandPage/MigrationBrandPage";
import LandingPro from "./pages/LandingPro/LandingPro";
import SupportModal from "./components/Modals/SupportModal/SupportModal";
import ConfirmPassword from "./pages/ConfirmPassword/ConfirmPassword";
import GetUnlimAccess from "./components/Modals/SelectPlanModal/GetUnlimAccess";
import React from "react";
import LogIn from "./components/Modals/LogIn/LogIn";

type PrivateRouteProps = {
    auth: {
        isAuthenticated: boolean;
    };
    children: React.JSX.Element;
};

const PrivateRoute = ({ auth: { isAuthenticated }, children }: PrivateRouteProps) => {
    return isAuthenticated ? children : <Navigate to='/admin/login' />;
};

function App() {
    const user = useSelector((state: any) => state.userState.user);
    const loginModal = useSelector((state: any) => state.mainState.logIn);
    const signInModal = useSelector((state: any) => state.mainState.signIn);
    const createPasswordModal = useSelector((state: any) => state.mainState.createPasswordModal);
    const welcomeModal = useSelector((state: any) => state.welcomeModalState.welcomeModal);
    const getInfoModal = useSelector((state: any) => state.getInfoState.getInfoModal);
    const selectPlanModal = useSelector((state: any) => state.selectPlanState.selectPlanModal);
    const paymentPage = useSelector((state: any) => state.selectPlanState.paymentDetailsPage);
    const auth = useSelector((state: any) => state.adminState.auth);
    const editPage = useSelector((state: any) => state.editPageState.editPage);
    const submitPage = useSelector((state: any) => state.submitPageState.submitPage);
    const editAdPage = useSelector((state: any) => state.submitPageState.editPage);
    const subscriptionPage = useSelector((state: any) => state.subscriptionState.subscriptionPage);
    const linkCopiedState = useSelector((state: any) => state.mainState.linkCopiedModal);
    const favoritedState = useSelector((state: any) => state.mainState.favoritedModal);
    const unfavoritedState = useSelector((state: any) => state.mainState.unfavoritedModal);
    const createdAdState = useSelector((state: any) => state.mainState.createdAdModal);
    const createCollectionState = useSelector((state: any) => state.MyCollectionState.createCollectionModal);
    const saveCollectionState = useSelector((state: any) => state.MyCollectionState.saveCollectionModal);
    const unlimCollectionState = useSelector((state: any) => state.MyCollectionState.unlimCollectionModal);
    const createdCollection = useSelector((state: any) => state.mainState.nameOfCreatedCollection);
    const createdCollectionModal = useSelector((state: any) => state.mainState.createdCollectionModal);
    const supportModal = useSelector((state: any) => state.supportState.supportModal);
    const getUnlimAccessModal = useSelector((state: any) => state.selectPlanState.getUnlimAccessModal);
    const changedPassword = useSelector((state: any) => state.mainState.passwordChangedModal);
    const location = useLocation();
    const isAdminPage = location.pathname.includes('/admin');
    useGetSessionQuery();

    return (
        <div className='App'>
            <div className='wrapper'>
                {!paymentPage && !isAdminPage && !editPage && !submitPage && !editAdPage && !subscriptionPage && <Header />}
                {loginModal && <LogIn />}
                {signInModal && <SignIn />}
                {createPasswordModal && <CreatePassword />}
                {(welcomeModal || (user && !user?.discoveryChannel)) && <WelcomeModal />}
                {getInfoModal && <GetInfo />}
                {selectPlanModal && <SelectPlan />}
                {linkCopiedState && <LinkCopied textToDisplay="Link Copied"/>}
                {favoritedState && <LinkCopied textToDisplay="Favorited"/>}
                {unfavoritedState && <LinkCopied textToDisplay="Unfavorited"/>}
                {createdAdState && <LinkCopied textToDisplay="Ad Created"/>}
                {createdCollectionModal && <LinkCopied textToDisplay={`Ad saved in ${createdCollection}`}/>}
                {changedPassword && <LinkCopied textToDisplay="Password successfully Changed"/>}
                {createCollectionState && <CreateCollection />}
                {saveCollectionState && <SaveCollection />}
                {unlimCollectionState && <UnlimCollections />}
                {supportModal && <SupportModal />}
                {getUnlimAccessModal && <GetUnlimAccess />}

                <Routes>
                    <Route index path='/' element={<MainPage />} />
                    {(
                        <Route path='/creator-page/*' element={<CreatorPage />}>
                            <Route path='' element={<Navigate to='/creator-page/ads' />} />
                            <Route path='ads/:id' element={<ThumbAdsWrapper />} />
                            <Route path='collections/:id' element={<ThumbCollectionWrapper />} />
                        </Route>
                    )}
                    <Route path='/brand-page/:id' element={<BrandPage />} />
                    <Route path='/collection-page/:id' element={<CollectionVideosPage />} />
                    <Route path='/collection-page/user/:id' element={<CollectionPage />} />
                    <Route path='/search-page' element={<SearchPage />} />
                    <Route path='/project-page' element={<ProjectPage />} />
                    <Route path='/payment-page' element={<PaymentPage />} />
                    <Route path='/settings' element={<EditProfile />} />
                    <Route path='/submit-work' element={<SubmitWorkPage />} />
                    <Route path='/edit-work' element={<EditWorkPage />} />
                    <Route path='/my-subscription' element={<MySubscription />} />
                    <Route path='/my-subscription/billing' element={<BillingPage />} />
                    <Route path='/ad/:id' element={<AdPage />} />
                    <Route path='/privacy' element={<PrivacyPage />} />
                    <Route path='/terms' element={<TosPage />} />
                    <Route path='/pro' element={<LandingPro />} />
                    <Route path='/reset-password' element={<ConfirmPassword />} />

                    <Route
                        path='/admin/*'
                        element={
                            <PrivateRoute auth={{ isAuthenticated: auth }}>
                                <AdminPanel />
                            </PrivateRoute>
                        }
                    >
                        <Route path='' element={<Navigate to='/admin/video' />} />
                        <Route path='video' element={<VideoModeration />} />
                        <Route path='user' element={<UserManagement />} />
                        <Route path='analytics' element={<AnalyticsPage />} />
                        <Route path='submit' element={ <SubmitWorkPageAdmin /> }/>
                        <Route path='create-user' element={<CreateUser />}/>
                        <Route path='migration/ugc' element={<MigrationPage />}/>
                        <Route path='migration/brand' element={<MigrationBrandPage />}/>
                    </Route>

                    <Route path='/admin/login' element={<AdminLogin />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
