import React from "react";
import './ThumbWrapper.scss'
import './MediaThumbWrapper.scss'
import Thumb from "../Thumb/Thumb";
import { useSelector } from "react-redux";
import userProfileImg from "../../assets/images/user-avatar.png";
import { useGetAdQuery } from "../../services/adinspiration-api";
import { useParams } from "react-router-dom";

const ThumbAdWrapper = () => {
  const urlParam = useParams();
  const guestStatus = useSelector((state: any) => state.logInState.guestStatus);
  const {data, isLoading, error} = useGetAdQuery(urlParam?.id);

  if (error) {
    return <div>Something went wrong</div>
  }
  if (isLoading) {
    return null;
  }

  const pageDate = data?.videos;

  if (guestStatus) {
    return (
      <div>
        <div className="thumbAdWrapper">
          {isLoading ? null : <div key={pageDate!._id}>
            <Thumb
              key={pageDate?.playbackIds[0]?.id}
              avatar={pageDate?.user.avatar || userProfileImg}
              userName={pageDate!.user.firstName}
              videoId={pageDate?._id!}
              playbackId={pageDate!.playbackIds[0].id!}
              viewerId={'Guest'}
              videoTitle={pageDate!.title}
              //isFavorite={item.isFavorite}
              userId={pageDate?.user._id}
              companyTypeP={pageDate?.user.companyType}
              originalLink={pageDate?.originalLink}
            />
          </div>}
        </div>
      </div>
    );
  }


  return (
    <div>
        <div className="thumbAdWrapper">
          {isLoading ? null : <div key={pageDate!._id}>
            <Thumb
              key={pageDate?.playbackIds[0]?.id}
              avatar={pageDate?.user.avatar || userProfileImg}
              userName={pageDate!.user.firstName}
              videoId={pageDate?._id!}
              playbackId={pageDate!.playbackIds[0].id!}
              viewerId={pageDate?.user._id!}
              videoTitle={pageDate!.title}
              //isFavorite={item.isFavorite}
              userId={pageDate?.user._id!}
              companyTypeP={pageDate?.user.companyType!}
              originalLink={pageDate?.originalLink!}
            />
          </div>}
        </div>
    </div>
  );
}

export default ThumbAdWrapper;
